import { commonMutations } from '../shared/commonMutations';
import { setAllowedSection } from './types';

export const mutations = {
    ...commonMutations,

    [setAllowedSection](state, {id, names}) {
        const restriction = state.list.find((restriction) => restriction.userAzureObjectId === id);
        if (restriction) {
            restriction.allowedSections = names.map((name) => {
                return {name};
            });
        }
    },
};
