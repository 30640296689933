import { setColumns, setColumnContent, setColumnType } from './columnTypes';
import { blockFactory } from '../blocks/blockFactory';
import { columnFactory } from './columnFactory';

export const columnMutations = {
    [setColumns](state, { sectionIndex, columnsCount, blockIndex }) {
        /** @type ISection */
        const section = state.current.sections[sectionIndex];

        if (section) {
            section.blocks.splice(blockIndex, 1, blockFactory(columnsCount));
        }
    },

    [setColumnType](state, { sectionIndex, blockIndex, columnIndex, type }) {
        /** @type ISection */
        const section = state.current.sections[sectionIndex];
        const block = section.blocks[blockIndex];

        block.columns[columnIndex] = columnFactory(type);
    },

    [setColumnContent](state, { sectionIndex, blockIndex, columnIndex, content }) {
        /** @type ISection */
        const section = state.current.sections[sectionIndex];
        const block = section.blocks[blockIndex];
        const column = block.columns[columnIndex];

        column.content = content;
    },
};
