export function splice2d(grid, row, col, newContent) {
    return [
        ...grid.slice(0, row),
        [
            ...grid[row].slice(0, col),
            newContent,
            ...grid[row].slice(col + 1),
        ],
        ...grid.slice(row + 1),
    ];
}
