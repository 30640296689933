import { api } from '../api';
import { commonActionsFactory } from '../shared/commonActions';
import { Resource } from '../shared/Resource';
import { wrapRequest } from '../shared/wrapRequest';
import { addToList, deleteFromList, updateList } from './types';

const tagsResource = new Resource(api, 'Categories');

export const actions = {
    ...commonActionsFactory(tagsResource),

    async create(context, newItem) {
        const response = await wrapRequest(context, async () => {
            return await tagsResource.create(newItem);
        });

        const item = {
            ...newItem,
            id: response.data,
        };

        context.commit(addToList, item);
        return item;
    },

    // TODO: make sure commonActions.delete works and drop this
    async delete(context, itemToDelete) {
        const response = await wrapRequest(context, async () => {
            return await tagsResource.delete(itemToDelete.id);
        });

        if (response.status < 400) {
            context.commit(deleteFromList, itemToDelete.id);
        }
    },

    async patch(context, patchedItem) {
        const response = await wrapRequest(context, async () => {
            return await tagsResource.patch(patchedItem);
        });

        if (response.status < 400) {
            context.commit(updateList, patchedItem);
        }
    },
};
