import { createRouter, createWebHistory } from 'vue-router';
import { adalWrapper } from '../store/adalWrapper.js';
import store from '../store';
import CategoriesList from './categories/CategoriesList.vue';
import CategoryItemEdit from './categories/itemEdit/CategoryItemEdit.vue';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            meta: { requiresAuthentication: true },
            path: '/categories/:id',
            name: 'categories-id',
            props: true,
            component: CategoryItemEdit,
            children: [
                {
                    path: 'addProduct',
                    name: 'addProduct',
                    props: true,
                    async component() {
                        return await import('./products/AddProductView.vue');
                    },
                },
            ],
        },

        {
            meta: { requiresAuthentication: true },
            path: '/products/:id',
            name: 'products-id',
            async component() {
                return await import('./products/ProductItemPage.vue');
            },
            props: true,
        },

        {
            meta: { requiresAuthentication: true },
            path: '/print',
            name: 'print',
            async component() {
                return await import('./products/itemView/PrintProduct.vue');
            },
            props(route) {
                const ids = route.query.ids;
                const skip = 'skip' in route.query ? route.query.skip : [];

                return {
                    skip: Array.isArray(skip) ? skip.map(index => parseInt(index)) : [parseInt(skip)],
                    ids: Array.isArray(ids) ? ids : [ids],
                };
            },
        },

        {
            meta: { requiresAuthentication: true, noBreadCrumbs: true },
            path: '/tags',
            name: 'tags',
            async component() {
                return await import('./tags/TagsPage.vue');
            },
        },

        {
            meta: { requiresAuthentication: true, noBreadCrumbs: true },
            path: '/restrictions',
            name: 'restrictions',
            async component() {
                return await import('./restrictions/RestrictionsPage.vue');
            },
        },

        {
            meta: { requiresAuthentication: true, noBreadCrumbs: true },
            path: '/audit-log',
            name: 'audit-log',
            async component() {
                return await import ('./audit-log/AuditLogPage.vue');
            },
        },

        {
            meta: { requiresAuthentication: true },
            path: '/audit-log/print',
            name: 'audit-print',
            props: true,
            async component() {
                return await import('./audit-log/AuditPrint.vue');
            },
        },

        {
            meta: { requiresAuthentication: true },
            path: '/audit-log/:id/:itemName',
            name: 'changes',
            props: true,
            async component() {
                return await import('./audit-log/ChangesPage.vue');
            },
        },



        {
            meta: { requiresAuthentication: true, noBreadCrumbs: true },
            path: '/:categoryId?',
            name: 'index',
            component: CategoriesList,
            props: true,
            children: [
                {
                    path: '/addCategory',
                    name: 'addCategory',
                    async component() {
                        return await import('./categories/CategoryCreate.vue');
                    },
                },
            ],
        },

        // Callback bug without reproduction, check "[BASSD-19] Blank page after signing in"
        {
            meta: { requiresAuthentication: true },
            path: '/null',
            name: 'null',
            redirect: '/',
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuthentication)) {
        // this route requires auth, check if logged in
        if (adalWrapper.getUserProfile()) {
            // only proceed if authenticated.
            next();
        } else {
            adalWrapper.login();
        }
    } else {
        next();
    }
});

router.afterEach((to) => {
    if (to.matched.some((record) => record.meta.noBreadCrumbs)) {
        store.commit('nav/setClean');
    }
});

export {router};
