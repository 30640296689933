<template>
    <v-btn icon color="primary" size="small" @click="$emit('addSection')">
        <v-icon size="large">add</v-icon>
    </v-btn>
</template>

<script>
export default {
    name: 'AddSectionButton',
    emits: ['addSection'],
};
</script>

<style scoped>

</style>
