import { subscriberPluginFactory } from './subscriberPlugin';
import { setDirty } from './dirty/dirtyTypes';

/**
 * @param {string} namespace
 * @param {string[]} triggerMutations
 * @return {Function}
 */
export function dirtyFlagPluginFactory(namespace, triggerMutations) {
    const nsPrefix = `${namespace}/`;
    const cleanNsRe = new RegExp(`^${nsPrefix}`);

    return subscriberPluginFactory(
        namespace,
        (mutation, state, store) => {
            const type = mutation.type.replace(cleanNsRe, '');
            if (triggerMutations.includes(type)) {
                store.commit(`${nsPrefix}${setDirty}`, true);
            }
        }
    );
}
