import { commonActionsFactory } from '../shared/commonActions.js';
import { api } from '../api.js';
import { wrapRequest } from '../shared/wrapRequest.js';
import { RestrictionsResource } from './RestrictionsResource.js';

const restrictionsResource = new RestrictionsResource(api, 'Authorization/UserRestrictions');

export const actions = {
    ...commonActionsFactory(restrictionsResource),

    async save(context, {id}) {
        const restriction = context.state.list.find((restriction) => restriction.userAzureObjectId === id);
        if (restriction) {
            await wrapRequest(context, async () => {
                return restrictionsResource.save(restriction);
            });
        }
    },
};
