<template>
    <div class="columnChooser py-5">
        <p class="subheading text-center">{{ $t('chooseColumnsAmount') }}</p>
        <v-row justify="space-around" class="columnButtons my-4">
            <v-btn
                v-for="i of 4"
                :key="i"
                :title="i"
                rounded="circle"
                class="columnCountBtn"
                size="100px"
                variant="flat"
                @click="$emit('chooseColumns', i)"
            >
                {{ i }}
            </v-btn>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'ColumnCountSelector',
    emits: ['chooseColumns'],
};
</script>

<style scoped>
.columnChooser {
    background-color: #e8e8e8;
}

.columnButtons {
    padding: 0 25em;
}

.columnCountBtn {
    background-color: #2e73b6;
    color: white;
}
</style>
