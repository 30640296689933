/**
 * @param {number|null} columnCount
 * @return {IBlock}
 */
import nanoid from 'nanoid';

export function blockFactory(columnCount) {
    return {
        id: nanoid(),
        columns: columnCount === null ? null : Array(columnCount).fill(null),
    };
}
