<template>
    <div :class="{overridden: overridden === false, readOnlyBlock: readOnly}" class="mb-3 block">
        <v-row no-gutters v-if="block.columns !== null" class="columns">
            <v-col
                v-bind="columnAttrs(block)"
                v-for="(column, columnIndex) of block.columns"
                :key="[block.id, columnIndex].join('-')"
                :class="{readOnly: readOnly, embedded: block.embedded}"
                cols="12"
                class="pa-1"
            >
                <column-type
                    v-if="column === null"
                    @setColumnType="onSetColumnType(columnIndex, $event)"
                />
                <column-item
                    v-else
                    :column="column"
                    :read-only="readOnly"
                    :uuid="block.id"
                    :column-index="columnIndex"
                    @input="onColumnInput(columnIndex, $event)"
                />
            </v-col>

            <div v-if="movable && !readOnly && !block.embedded" :class="{readOnly: readOnly}" class="moveControls">
                <v-icon @click="$emit('moveUp')">keyboard_arrow_up</v-icon>
                <v-icon @click="$emit('moveDown')">keyboard_arrow_down</v-icon>
            </div>
            <v-tooltip v-if="readOnly && !block.embedded" location="bottom">
                <template #activator="{ props }">
                    <div v-ripple v-bind="props" class="embedBlock pointer" @click="$emit('showEmbedBlock')">
                        <v-icon size="small" color="white">add_circle_outline</v-icon>
                    </div>
                </template>
                {{ $t('embedBlock') }}
            </v-tooltip>
        </v-row>
        <block-actions
            v-if="block.columns !== null"
            :read-only="readOnly"
            :overridden="overridden"
            :is-general-info="block.isGeneralInfo"
            :is-embedded="block.embedded"
            class="toolbar"
            @add="$emit('add')"
            @copy="$emit('copy')"
            @delete="$emit('delete')"
            @markBlockToggled="onMarkBlockToggled($event)"
            @markBlockGeneral="onMarkBlockGeneral($event)"
            @unembed="$emit('unembed')"
        />

        <column-count-selector
            v-if="block.columns === null"
            class="columns chooser"
            @chooseColumns="onSetColumns"
        />
    </div>
</template>

<script>
import ColumnItem from '../column/ColumnItem';
import BlockActions from './BlockActions';
import ColumnCountSelector from '../column/ColumnCountSelector';
import ColumnType from '../column/ColumnType';
import { columnAttrs } from './columAttrs';

export default {
    name: 'BlockItem',

    components: {
        ColumnType,
        ColumnCountSelector,
        BlockActions,
        ColumnItem,
    },

    mixins: [
        columnAttrs,
    ],

    props: {
        block: {
            type: Object,
            required: true,
        },
        movable: {
            type: Boolean,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        overridden: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['markBlockGeneral', 'markBlockToggled', 'setColumns', 'setColumnContent', 'setColumnType', 'unembed', 'delete', 'copy', 'add', 'showEmbedBlock', 'moveUp', 'moveDown'],

    methods: {
        onSetColumnType(columnIndex, type) {
            this.$emit('setColumnType', {
                columnIndex,
                type,
            });
        },

        onColumnInput(columnIndex, content) {
            this.$emit('setColumnContent', {
                columnIndex,
                content,
                type: this.block.columns[columnIndex].type,
            });
        },

        onSetColumns(columnsCount) {
            this.$emit('setColumns', columnsCount);
        },

        onMarkBlockToggled(value) {
            this.$emit('markBlockToggled', {value});
        },

        onMarkBlockGeneral(value) {
            this.$emit('markBlockGeneral', {value});
        },
    },
};
</script>

<style scoped lang="stylus">
@require './block.styl';
@require '../readOnly.styl';

.column {
    & + & {
        margin-left: 10px;
    }
}

.block {
    border-left: 4px solid #e8e8e8;
    transition: all 150ms linear;

    &:hover {
        border-left-color: #2e73b6;
    }
}

.embedded {
    pointer-events: none;
    border-left-color: darkgray;
    opacity: 0.5;
}

.readOnlyBlock:hover {
    border-left-color: hsla(210, 30%, 45%, 0.9)
}

.toolbar {
    width: 52px;
    flex-basis: 52px;
    flex-grow: 0;
    align-self: center;
    opacity: 0;
    pointer-events: none;
    transition: all 150ms linear;

    .block:hover & {
        opacity: 1;
        pointer-events: auto;
    }
}

.moveControls {
    position: absolute;
    bottom: 0;
    opacity: 0.5;
    left: 10%;
    right: 10%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 1;
    }
}

.embedBlock {
    position: absolute;
    bottom: 0px;
    left: 10px;
    right: 10px;
    background-color: #2e73b6;
    text-align: center;
    opacity: 0.6;

    .v-icon {
        opacity: 0;
    }

    &:hover {
        opacity: 1;

        .v-icon {
            opacity: 1;
        }
    }
}
</style>
