<template>
    <v-snackbar :model-value="error" absolute location="bottom" color="error">{{ error }}</v-snackbar>
</template>

<script>
export default {
    name: 'ErrorNotification',

    props: {
        error: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style scoped>

</style>
