export function state() {
    const defaultAuthState = {
        profile: null,
        isAuthenticated: false,
        isAdmin: false,
        isReviewer: false,
        pretendUser: localStorage.getItem('isUser') !== null,
    };

    return defaultAuthState;
}
