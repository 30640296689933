<template>
    <div v-if="showPrint">
        <router-view />
    </div>
    <v-app v-else>
        <top-nav />
        <v-main>
            <v-container v-show="isAuthenticated" fluid>
                <welcome-message class="centerBlock mb-2" />
                <router-view />
                <scroll-to-top />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import {mapState} from 'vuex';
import TopNav from './TopNav';
import ScrollToTop from './ScrollToTop';
import WelcomeMessage from './WelcomeMessage.vue';
import 'vuetify/styles';

export default {
    components: {
        WelcomeMessage,
        ScrollToTop,
        TopNav,
    },

    computed: {
        ...mapState('auth', ['isAuthenticated']),

        showPrint() {
            return this.$route.name === 'print';
        },
    },
};
</script>
<style lang="stylus">
</style>
