<template>
    <create-item-dlg
        :title="$t('createProduct')"
        :confirm-disabled="saveBtnDisabled"
        :confirm-label="$t('productCreationConfirm')"
        @confirm="onConfirmCreate"
        @cancel="onCancelCreate"
    >
        <v-text-field
            v-model="title"
            :label="$t('productCreationTitle')"
            variant="underlined"
        />

        <date-picker v-model="createdAt" :label="$t('productCreationDate')" />

        <v-select
            v-model="associatedCategory"
            :label="$t('productCreationCategory')"
            :items="categories"
            :loading="isFetchingCategories"
            item-title="title"
            item-value="id"
            variant="underlined"
        />
    </create-item-dlg>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import DatePicker from '../shared/DatePicker';
import CreateItemDlg from '../shared/CreateItemDlg';

export default {
    name: 'ProductCreateForm',

    components: {
        CreateItemDlg,
        DatePicker,
    },

    props: {
        categoryId: {
            type: String,
            required: true,
        },
    },
    emits: ['cancel'],

    data() {
        return {
            title: '',
            createdAt: new Date(),
            associatedCategory: this.categoryId,
            menu: null,
        };
    },

    computed: {
        ...mapState('categories', {
            categories: 'list',
            isFetchingCategories: 'isFetching',
        }),

        saveBtnDisabled() {
            return this.title.trim() === '' || (!this.createdAt);
        },
    },

    async mounted() {
        await this.listProductGroups();
    },

    methods: {
        ...mapActions('products', ['create']),
        ...mapActions('categories', {listProductGroups: 'list'}),

        async onConfirmCreate() {
            const id = await this.create({
                product: {
                    title: this.title,
                    createdAt: this.createdAt,
                },
                parentId: this.categoryId,
            });

            this.$router.push({ name: 'products-id', params: {id} });
        },

        onCancelCreate() {
            this.$emit('cancel');
        },
    },
};
</script>

<style scoped>

</style>
