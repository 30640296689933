<template>
    <nav class="sectionTabs">
        <section-tab
            v-for="(section, index) of sections"
            :key="section.id.toString() + index"
            :section="section"
            :selected="value === index"
            :editable="isTabEditable(section)"
            :deletable="!section.readOnly && !readonly"
            @select="onTabClick(index)"
            @delete="showDeleteConfirmation(section)"
            @rename="onRename"
        />
        <add-section-button v-if="!readonly" class="addSectionBtn" @addSection="onAddSectionClick" />
        <confirmation-dlg
            :show="sectionToDelete !== null"
            @cancel="hideDeleteConfirmation"
            @confirm="onSectionDeleteConfirmed"
        >
            <template #title>
                <span>{{ $t('sectionDeleteConfirmationTitle', {title: sectionToDelete && sectionToDelete.title}) }}</span>
            </template>
            <template #body>
                <span>{{ $t('sectionDeleteConfirmationBody') }}</span>
            </template>
        </confirmation-dlg>
    </nav>
</template>

<script>
import AddSectionButton from './AddSectionButton';
import SectionTab from './SectionTab';
import ConfirmationDlg from '../ConfirmationDlg';

export default {
    name: 'SectionTabs',

    components: {
        ConfirmationDlg,
        SectionTab,
        AddSectionButton,
    },

    props: {
        value: {
            type: Number,
            required: true,
        },

        sections: {
            type: Array,
            required: true,
        },
        readonly: {
            type: Boolean
        }
    },
    emits: ['input', 'addSection', 'renameSection', 'deleteSection'],

    data() {
        return {
            editedSectionTitle: null,
            sectionToDelete: null,
        };
    },

    methods: {
        isTabEditable(section) {
            return !section.readOnly && !this.readonly;
        },

        onTabClick(index) {
            this.$emit('input', index);
        },

        onAddSectionClick() {
            this.$emit('addSection', {title: this.$t('enterSectionName')});

            this.$nextTick(() => {
                const index = this.sections.length - 1;
                this.onTabClick(index);
            });
        },

        onRename({title, id}) {
            this.$emit('renameSection', {
                title,
                id,
            });
        },

        showDeleteConfirmation(section) {
            this.sectionToDelete = section;
        },

        hideDeleteConfirmation() {
            this.sectionToDelete = null;
        },

        onSectionDeleteConfirmed() {
            this.$emit('deleteSection', this.sectionToDelete.id);
            this.hideDeleteConfirmation();
        },
    },
};
</script>

<style scoped lang="stylus">
.sectionTabs {
    position: relative;
    z-index: 10;
}

.pointer {
    cursor: pointer;
}

.addSectionBtn {
    position: relative;
    top: -5px;
}
</style>
