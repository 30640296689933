/**
 * @return {IProductState}
 */
export function state() {
    const defaultState = {
        isFetching: false,
        error: null,

        list: [

        ],

        current: null,
        previousRevision: null,
        previousPublishedRevision: null,
        isDirty: false,
    };

    return defaultState;
}
