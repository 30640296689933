import { embeddedSectionTitle } from '../../../store/products/embeddedSection';

export const predefinedSections = [
    'Del 0 – Overordnede krav og prøveomfang',
    'Del 1 – Produktspesifikasjon',
    'Del 2 – Armering',
    'Del 3 – Tetthet',
    'Del 4 – Merkebestemmelser',
    'Del 5 – Max kvalitet',
    'Del 6 – Tillegg',
    embeddedSectionTitle,
];
