/**
 * Skips unfinished and broken blocks
 * @param {IBlock} block
 * @return {boolean}
 */
export function isBlockValid(block) {
    if (!block.columns) {
        return false;
    }

    if (block.columns.length > 1) {
        return true;
    } else {
        return block.columns[0] !== null;
    }
}
