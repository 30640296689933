export const approvable = {
    data() {
        return {
            showApproveDlg: false,
        };
    },

    methods: {
        onApproveClick() {
            this.showApproveDlg = true;
        },

        async onConfirmApprove() {
            await this.approveCurrent();

            this.setDirty(false);
            this.showApproveDlg = false;
        },
    },
};
