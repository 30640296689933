<template>
    <article v-scroll="onScroll" :class="{visible, }" class="scrollToTop pointer print-hidden" @click="scrollUp">
        <v-icon class="ma-3 pa-1 arrow">arrow_upward</v-icon>
    </article>
</template>

<script>
export default {
    name: 'ScrollToTop',

    data() {
        return {
            visible: false,
        };
    },

    methods: {
        onScroll() {
            this.visible = window.scrollY > 64; // navbar height
        },

        scrollUp() {
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
    },
};
</script>

<style scoped lang="stylus">
.scrollToTop {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(90%, 90%, 90%, 0);
    opacity: 0;
    pointer-events: none;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    transition: all 150ms linear;

    &.visible {
        opacity: 1;
        pointer-events: auto;
        transition: all 150ms linear;

        &:hover {
            opacity: 1;
            background-color: rgba(90%, 90%, 90%, 0.5);
            transition: all 150ms linear;
        }
    }

    .arrow {
        color: #333333;
        border-radius: 50%;
        border: 1px solid currentColor;
    }
}
</style>
