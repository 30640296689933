<template>
    <article class="categoriesList centerBlock">
        <h2>{{ $t('categories') }}</h2>
        <v-progress-linear color="primary" :active="isFetching" indeterminate />

        <categories-list-management
            :is-admin="viewAsAdmin"
            :is-reviewer="isReviewer"
            :selected-products="selectedProducts"
            @massApprove="onBulkApprove"
            @massPublish="onBulkPublish"
            @filter="onFilter"
        />

        <categories-grid
            :list="viewAsAdmin ? categoriesAdmin : categoriesUser"
            :is-admin="viewAsAdmin"
            :selected="selected"
            :initial-expand="categoryId"
            @rowSelected="onRowsSelected"
            @deleteCategory="categoryToDelete = $event"
            @deleteProduct="productInfoToDelete = $event"
        />

        <confirmation-dlg
            :show="categoryToDelete !== null"
            @cancel="categoryToDelete = null"
            @confirm="onDeleteCategory"
        >
            <template #title>
                <span>{{ $t('categoryDeleteConfirmationTitle', {title: categoryToDelete && categoryToDelete.title}) }}</span>
            </template>
            <template #body>
                <span>{{ $t('categoryDeleteConfirmationBody') }}</span>
            </template>
        </confirmation-dlg>

        <confirmation-dlg
            :show="productInfoToDelete !== null"
            @cancel="productInfoToDelete = null"
            @confirm="onDeleteProduct"
        >
            <template #title>
                <span>{{ $t('productDeleteConfirmationTitle', {title: productInfoToDelete && productInfoToDelete.product.title}) }}</span>
            </template>
            <template #body>
                <span>{{ $t('productDeleteConfirmationBody') }}</span>
            </template>
        </confirmation-dlg>

        <confirmation-dlg
            :show="productsToApprove !== null || productsToPublish !== null"
            :confirm-icon="bulkConfirmIcon"
            confirm-color="primary"
            @cancel="onBulkCancel"
            @confirm="onBulkConfirm"
        >
            <template #title>
                <span>{{ bulkOperationTitle }}</span>
            </template>
            <template #body>
                <div>
                    <p>{{ bulkOperationBody }}</p>
                    <ul style="padding-left: 24px;">
                        <li v-for="(title, index) of bulkOperationList" :key="index">{{ title }}</li>
                    </ul>
                </div>
            </template>
            <template #confirmLabel>{{ bulkOperationTitle }}</template>
        </confirmation-dlg>

        <router-view />

        <error-notification :error="error" />
    </article>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex';
import ErrorNotification from '../shared/ErrorNotification';
import CategoriesListManagement from './list/CategoriesListManagement';
import CategoriesGrid from './list/CategoriesGrid';
import ConfirmationDlg from '../shared/ConfirmationDlg';
import {router} from "@/router";

export default {
    name: 'CategoriesList',

    components: {
        ConfirmationDlg,
        CategoriesGrid,
        CategoriesListManagement,
        ErrorNotification,
    },
    props: {
        categoryId: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            selected: {},
            categoryToDelete: null,
            productInfoToDelete: null,

            productsToApprove: null,
            productsToPublish: null,
        };
    },

    computed: {
        ...mapState('categories', {
            isFetching: 'isFetching',
            error: 'error',
        }),

        ...mapState('auth', ['isReviewer']),
        ...mapGetters('auth', ['viewAsAdmin']),

        ...mapGetters('filter', {categoriesUser: 'visibleCategoriesUser', categoriesAdmin: 'visibleCategoriesAdmin'}),

        selectedProducts() {
            const flattened = [];

            for (const items of Object.values(this.selected)) {
                flattened.push(...items);
            }

            return flattened;
        },

        bulkOperationTitle() {
            if (this.productsToApprove !== null) {
                return this.$t('approve');
            } else if (this.productsToPublish !== null) {
                return this.$t('publish');
            } else {
                return '';
            }
        },

        bulkOperationBody() {
            if (this.productsToApprove !== null) {
                return this.$t('approveConfirmationBody');
            } else if (this.productsToPublish !== null) {
                return this.$t('publishConfirmationBody');
            } else {
                return '';
            }
        },

        bulkOperationList() {
            if (this.productsToApprove !== null) {
                return this.productsToApprove.map(product => product.title);
            } else if (this.productsToPublish !== null) {
                return this.productsToPublish.map(product => product.title);
            } else {
                return [];
            }
        },

        bulkConfirmIcon() {
            if (this.productsToApprove !== null) {
                return 'check_box';
            } else if (this.productsToPublish !== null) {
                return 'cloud_upload';
            } else {
                return '';
            }
        },
    },

    watch: {
        viewAsAdmin() {
            const includeDeleted = this.$route.query.includeDeleted?.toLowerCase() === 'true'
                && this.viewAsAdmin;
            this.fetchData({ includeDeleted });
        },
    },

    async mounted() {
        const includeDeleted = this.$route.query.includeDeleted?.toLowerCase() === 'true'
            && this.viewAsAdmin;
        await this.fetchData({ includeDeleted });
    },

    methods: {
        ...mapActions('categories', {
            listWithProducts: 'listWithProducts',
            listWithPublishedProducts: 'listWithPublishedProducts',
            deleteCategory: 'delete',
        }),

        ...mapActions('products', {
            deleteProduct: 'delete',
            bulkApprove: 'bulkApprove',
            bulkPublish: 'bulkPublish',
        }),

        fetchData(params) {
            return (this.viewAsAdmin || this.isReviewer) ? this.listWithProducts(params) : this.listWithPublishedProducts();
        },

        onRowsSelected(categoryId, products) {
            this.selected[categoryId] = products;
        },

        async onDeleteCategory() {
            await this.deleteCategory(this.categoryToDelete);
            await this.$nextTick(() => this.categoryToDelete = null);
        },

        async onDeleteProduct() {
            await this.deleteProduct(this.productInfoToDelete);
            await this.$nextTick(() => this.productInfoToDelete = null);
        },

        async onBulkApprove(products) {
            this.productsToApprove = products;
        },

        async onBulkPublish(products) {
            this.productsToPublish = products;
        },

        closeBulkDialogue() {
            this.productsToApprove = null;
            this.productsToPublish = null;
        },

        onBulkCancel() {
            this.closeBulkDialogue();
        },

        async onBulkConfirm() {
            if (this.productsToApprove !== null) {
                await this.bulkApprove(this.productsToApprove);
            } else if (this.productsToPublish) {
                await this.bulkPublish(this.productsToPublish);
            }

            this.closeBulkDialogue();

            this.selected = {};

            const includeDeleted = this.$route.query.includeDeleted?.toLowerCase() === 'true';
            await this.fetchData({ includeDeleted });
        },

        onFilter(params) {
            router.push({ path: '/', query: params });
            void this.fetchData(params);
        }
    },
};
</script>

<style scoped lang="stylus">
@import url('../shared/tables.styl');

.categoriesList {
    h1 {
        margin-bottom: 5px;
    }
}

.listAction {
    color: #505050;

    .v-icon {
        margin-right: 2px;
    }
}

.progress {
    padding: 20px 0;
}
</style>

<style lang="stylus">
/* separate, un-scope-d style tag is needed to override external classes */
.v-datatable__expand-col--expanded {
    border-bottom: 2px solid silver;
}

.v-table {
    thead {
        background-color: #2e73b6;
        th {
            color: white !important;
            .v-icon {
                margin-left: 0.5ex;
                color: white !important;
            }
        }
    }
}
</style>
