/**
 * @param {string} namespace
 * @param {Function} listener
 * @return {Function}
 */
export function subscriberPluginFactory(namespace, listener) {
    return function subscriberPlugin(store) {
        store.subscribe((mutation, state) => {
            if (mutation.type.startsWith(`${namespace}/`)) {
                listener(mutation, state, store);
            }
        });
    };
}
