import format from 'date-fns/fp/format';

function fmtFactory(fmtStr) {
    const fmtFn = format(fmtStr);
    return function (value) {
        if (!value) {
            return '';
        } else {
            return fmtFn(value);
        }
    };
}

export const formatDate = fmtFactory('dd.MM.YYYY');
export const formatDateTime = fmtFactory('dd.MM.YYYY HH:mm');
