<template>
    <item-title-cell :link-url="{name: 'categories-id', params: {id: item.id}}" :is-admin="isAdmin" :is-deleted="item.isDeleted">
        <span>{{ item.title }}</span>
        <v-icon>{{ expanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</v-icon>
        <template #prependBtn>
            <v-btn v-if="isAdmin && !item.isDeleted" icon variant="flat" @click.stop="$emit('addProduct', item.id)">
                <v-icon>add</v-icon>
            </v-btn>
        </template>
        <template #appendBtn>
            <v-btn v-if="isAdmin && !item.isDeleted" icon variant="flat" @click.stop="$emit('deleteCategory')">
                <v-icon>delete</v-icon>
            </v-btn>
        </template>
    </item-title-cell>
</template>

<script>
import ItemTitleCell from '../../shared/ItemTitleCell';

export default {
    name: 'CategoryItemTitle',

    components: {
        ItemTitleCell,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },

        expanded: {
            type: Boolean,
            required: true,
        },

        isAdmin: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['deleteCategory', 'addProduct'],
};
</script>

<style scoped>

</style>
