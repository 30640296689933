import { mapState } from 'vuex';

export const productGridCells = {
    props: {
        blockUuid: {
            type: String,
            required: true,
        },
        columnIndex: {
            type: Number,
            required: true,
        },
    },

    computed: {
        ...mapState('products', {
            gridAppendices(state) {
                return this.getAppendices(state.current);
            },
        }),
    },

    methods: {
        getAppendixValue(row, col, /** TGridAppendices */ appendices = this.gridAppendices) {
            if (appendices[row] && appendices[row][col]) {
                return appendices[row][col];
            }

            return '';
        },

        getAppendices(/** IProduct */item) {
            if (item?.gridAppendices) {
                return (item.gridAppendices[this.blockUuid] && item.gridAppendices[this.blockUuid][this.columnIndex]) || {};
            } else {
                return {};
            }
        },
    },
};
