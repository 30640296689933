<template>
    <article class="gridDimensionChooser">
        <!-- eslint-disable vue/no-v-html -->
        <h4>
            {{ $t('insertTable') }}<span v-html="headerAppendix" />
        </h4>
        <!-- eslint-enable vue/no-v-html -->
        <table @mouseleave="onTableExit">
            <tr v-for="row of 8" :key="row">
                <td
                    v-for="col of 10"
                    :key="col"
                    :class="{highlight: col <= selectedColumn && row <= selectedRow}"
                    :title="col + '×' + row"
                    @click="onCellClick"
                    @mouseenter="onCellEnter(row, col)"
                />
            </tr>
        </table>
    </article>
</template>

<script>
export default {
    name: 'GridDimensionChooser',
    emits: ['setGridDimensions'],

    data() {
        return {
            selectedRow: -1,
            selectedColumn: -1,
        };
    },

    computed: {
        headerAppendix() {
            if (this.selectedRow === -1 || this.selectedColumn === -1) {
                return '';
            } else {
                return `: ${this.selectedColumn}×${this.selectedRow}`;
            }
        },
    },

    methods: {
        onCellClick() {
            this.$emit('setGridDimensions', {
                cols: this.selectedColumn,
                rows: this.selectedRow,
            });
        },
        onCellEnter(row, col) {
            this.selectedRow = row;
            this.selectedColumn = col;
        },
        onTableExit() {
            this.selectedRow = -1;
            this.selectedColumn = -1;
        },
    },
};
</script>

<style scoped lang="stylus">
@require './gridEditor.styl';
$cellSize = 25px;

.gridDimensionChooser table td {
    width: $cellSize;
    height: $cellSize;
    border: 1px solid $gridBorderColor;
    margin: 0 2px 2px 0;

    &.highlight {
        background-color: aliceblue;
    }
}
</style>
