// network operations
export const setError = 'setError';
export const setFetching = 'setFetching';

// list operations
export const setList = 'setList';
export const updateList = 'updateList';
export const deleteFromList = 'deleteFromList';

// selected item operations
export const setCurrent = 'setCurrent';
export const updateCurrent = 'updateCurrent';
