<template>
    <v-select
        :items="selectItems"
        variant="underlined"
        class="revisionSelector"
        :model-value="current"
        @update:model-value="onInput"
        eager
    >
        <template #selection="{ item }">
            {{ formatRevision(item.title) }}
        </template>
        <template #item="{ props, item }">
            <v-list-item v-bind="props">
                <template #prepend>
                    <v-icon class="mr-2 statusIcon" :title="$t('status' + item.raw.status)" @click.stop>{{ item.raw.icon }}</v-icon>
                </template>
                <template #title>
                    {{ formatRevision(item.title) }}
                </template>
                <template v-if="item.value !== current" #append>
                    <v-icon>history</v-icon>
                </template>
            </v-list-item>
        </template>
    </v-select>
</template>

<script>

export default {
    name: 'RevisionSelector',
    props: {
        revisions: {
            type: Array,
            required: true,
        },

        onlyPublished: {
            type: Boolean,
            required: false,
        },

        current: {
            type: Number,
            required: true,
        },
    },
    emits: ['revertTo'],
    computed: {
        selectItems() {
            return this.revisions
                .filter(revision => revision.published || !this.onlyPublished)
                .map((revision) => {
                    const status = this.getStatusForRevision(revision);
                    return {
                        title: revision.modifiedAt,
                        value: revision.revision,
                        status,
                        icon: this.getIconForStatus(status),
                    };
                }).reverse();
        },
    },

    methods: {
        formatRevision(date) {
            if (!isNaN(date)) {
                return this.$t('noRecordsFound');
            }

            const dateObj = new Date(date);
            return `${dateObj.toLocaleDateString('nb-NO')} ${dateObj.toLocaleTimeString('nb-NO')}`
        },
        onInput(value) {
            this.$emit('revertTo', value);
        },

        /**
         * @param {Object} revision
         * @return {TProductStatus}
         */
        getStatusForRevision(revision) {
            if (revision.published) {
                return 'Published';
            } else {
                if (revision.approved) {
                    return 'RevisionApproved';
                }
            }

            return 'WorkInProgress';
        },

        /**
         * @param {TProductStatus} status
         * @return {string}
         */
        getIconForStatus(status) {
            if (status === 'Published') {
                return 'cloud_upload';
            } else if (status === 'RevisionApproved') {
                return 'check_box';
            } else {
                return 'edit';
            }
        },
    },
};
</script>

<style scoped lang="stylus">
.revisionSelector {
    z-index: 10;
}
.statusIcon {
    cursor: auto !important;
    opacity: 0.6;
}
</style>
