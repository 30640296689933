<template>
    <input
        :value="value"
        :placeholder="$t('enterSectionName')"
        required
        @input="onInput"
        @blur="onBlur"
    >
</template>

<script>
export default {
    name: 'TabTitleEditor',

    props: {
        value: {
            type: String,
            required: true,
        },
    },
    emits: ['stopEdit', 'input'],

    mounted() {
        this.$el.focus();
        this.$el.select();
    },

    methods: {
        onInput(event) {
            this.$emit('input', event.currentTarget.value.trim());
        },

        onBlur(event) {
            if (event.currentTarget.value.trim() === '') {
                event.preventDefault();
            } else {
                this.$emit('stopEdit');
            }
        },
    },
};
</script>

<style scoped>
input::placeholder {
    font-size: 90%;
    font-style: italic;
}
input:invalid {
    outline-color: #e65c5c;
}
input:invalid::placeholder {
    color: #e65c5c;
}

</style>
