import { mapActions } from 'vuex';

export function revisionedEntity(storeNS) {
    return {
        data() {
            return {
                revision: null,
            };
        },

        watch: {
            async revision(newRevision) {
                if (newRevision == null) {
                    await this.read(this.id);
                } else {
                    await this.readRevision({
                        id: this.id,
                        revision: newRevision,
                    });
                }
            },
        },

        methods: {
            ...mapActions(storeNS, ['readRevision']),
        },
    };
}
