<template>
    <v-breadcrumbs divider=">" class="ml-5">
        <v-breadcrumbs-item v-for="(breadcrumb, index) of breadcrumbs(viewAsAdmin)" :key="breadcrumb.title">
            <v-breadcrumbs-divider v-if="index !== 0" class="text-white mr-1" />
            <router-link :to="breadcrumb.link" class="breadcrumbLink">{{ breadcrumb.title }}</router-link>
        </v-breadcrumbs-item>
    </v-breadcrumbs>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TopBreadcrumbs',

    computed: {
        ...mapGetters('nav', ['breadcrumbs']),
        ...mapGetters('auth', ['viewAsAdmin']),
    },
};
</script>

<style scoped lang="stylus">
.breadcrumbLink {
    color: white !important;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
</style>
