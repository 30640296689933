import { api } from '../api.js';

const getIsAdminUrl = 'Authorization/UserRestrictions/profile';

export const actions = {
    async getIsAdmin(context) {
        const result = await api({
            method: 'get',
            url: getIsAdminUrl,
        });

        if (result) {
            context.commit('setIsAdmin', result.data.isAdmin);
            context.commit('setIsReviewer', result.data.isReviewer);
        }
    },
};
