<template>
    <v-alert
        v-model="visible"
        class="welcomeMessage"
        color="primary"
        closable
    >
        <p>{{ $t('loginWelcome') }}</p>
        <p class="mb-0">{{ $t('loginConfidential') }}</p>
    </v-alert>
</template>

<script>
export default {
    name: 'WelcomeMessage',

    data() {
        return {
            visible: !sessionStorage.getItem('welcomeMessage'),
        };
    },

    watch: {
        visible(newValue) {
            if (newValue) {
                sessionStorage.setItem('welcomeMessage', true);
            } else {
                sessionStorage.removeItem('welcomeMessage');
            }
        },
    },

    mounted() {
        setTimeout(this.onTimeout, 15 * 1000);
    },

    methods: {
        onTimeout() {
            this.visible = false;
        },
    },
};
</script>

<style scoped>
.welcomeMessage.v-alert {
    border-color: transparent !important;
}
.v-alert__dismissible .v-icon {
    color: white;
}
</style>
