<template>
    <v-tooltip location="right">
        <template #activator="{ props }">
            <v-btn v-bind="props" :small="small" color="white" icon variant="text" @click="$emit('click')">
                <v-icon :small="small">{{ icon }}</v-icon>
            </v-btn>
        </template>
        <slot />
    </v-tooltip>
</template>

<script>
export default {
    name: 'ContextActionBtn',

    props: {
        icon: {
            type: String,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['click'],
};
</script>

<style scoped>

</style>
