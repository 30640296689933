/**
 * @return {IFilterState}
 */
export function state () {
    const defaultState = {
        search: '',
        showPublished: true,
        showWorkInProgress: true,
        showRevisionApproved: true,

        selectedTag: null,
    };

    return defaultState;
}
