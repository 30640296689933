import { setFullList } from './types';
import { api } from '../api';
import { wrapRequest } from '../shared/wrapRequest';
import { commonActionsFactory } from '../shared/commonActions';
import { revisionedActionFactory } from '../shared/revisioned/revisionedActions';
import { CategoryResource } from './CategoryResource';

export const categoryResource = new CategoryResource(api, 'ProductGroups');

function hydrate({content, ...meta}) {
    const category = {
        productsCount: undefined,
        ...meta,
        sections: content ? content.sections : [],
        categoryId: meta.categoryId === '000000000000000000000000' ? null : meta.categoryId,
    };

    if (category.products) {
        category.products.forEach((product) => {
            if (product.productGroupRevision === null) {
                product.productGroupRevision = category.revision;
            }
        });
    }

    return category;
}

// eslint-disable-next-line no-unused-vars
function dehydrate({sections, productsCount, ...meta}) {
    return {
        ...meta,
        content: {
            sections,
        },
    };
}

export const actions = {
    ...commonActionsFactory(categoryResource, hydrate, dehydrate),

    ...revisionedActionFactory(categoryResource, hydrate),

    async listWithProducts(context, params) {
        const categoryResponse = await wrapRequest(context, async () => {
            return await categoryResource.listWithProducts(params);
        });

        if (categoryResponse && categoryResponse.status < 400) {
            context.commit(setFullList, categoryResponse.data.map(hydrate));
        }
    },

    async listWithPublishedProducts(context) {
        const categoryResponse = await wrapRequest(context, async () => {
            return await categoryResource.listWithPublishedProducts();
        });

        if (categoryResponse && categoryResponse.status < 400) {
            context.commit(setFullList, categoryResponse.data.map(hydrate));
        }
    },

    async create(context, newItem) {
        const response = await wrapRequest(context, async () => {
            return await categoryResource.create(dehydrate(newItem));
        });

        return response.data;
    },
};
