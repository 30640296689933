<template>
    <v-row :class="{wider: !isAdmin}" align-center class="itemTitleCell">
        <slot />
        <v-spacer />
        <slot name="prependBtn" />
        <edit-row-btn v-if="isAdmin && !isDeleted" :to="linkUrl" />
        <v-btn v-if="isAdmin && isDeleted" :to="linkUrl" variant="flat" icon @click.stop>
            <v-icon>visibility</v-icon>
        </v-btn>
        <slot name="appendBtn" />
    </v-row>
</template>

<script>
import EditRowBtn from './EditRowBtn';

export default {
    name: 'ItemTitleCell',

    components: {
        EditRowBtn,
    },

    props: {
        linkUrl: {
            type: Object,
            required: true,
        },
        isAdmin: {
            type: Boolean,
            required: true,
        },
        isDeleted: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style scoped lang="stylus">
.itemTitleCell {
    width: 850px;
    align-items: center;
    flex-wrap: nowrap;
    &.wider {
        // when in user mode, we hide status column and should compensate for that
        // or else
        width: calc(850px + 200px);
    }
}
</style>
