<template>
    <article class="categoriesGrid">
        <v-data-table
            ref="categoryTable"
            v-model:expanded="expanded"
            :items="list"
            :headers="headers"
            :no-data-text="$t('noProductsFound')"
            class="biggerTextGrid elevation-1 productCount"
            :items-per-page="0"
            item-key="id"
            hover
        >
            <template #item="{ internalItem, item, toggleExpand, isExpanded}">
                <tr v-bind="checkIfInitial(item)" @click="toggleExpand(internalItem)" :class="{ deleted: item.isDeleted }">
                    <td class="checkboxColumn">
                        <div class="placeholder" />
                    </td>
                    <td class="pointer titleColumn ">
                        <category-item-title
                            :item="item"
                            :is-admin="isAdmin"
                            :expanded="isExpanded(internalItem)"
                            @addProduct="createProductIn = $event"
                            @deleteCategory="$emit('deleteCategory', item)"
                        />
                    </td>
                    <td v-if="isAdmin || isReviewer" class="dateColumn">
                        <div class="placeholder" />
                    </td>

                    <td class="dateColumn">
                        <div class="placeholder" />
                    </td>

                    <td v-if="isAdmin" class="statusColumn">
                        <div class="placeholder" />
                    </td>

                    <td class="countColumn">
                        <div class="placeholder">{{ item.visibleProducts.length }}</div>
                    </td>
                </tr>
            </template>

            <template #expanded-row="{item}">
                <product-grid
                    :category="item"
                    :is-admin="isAdmin"
                    :selected="selected[item.id]"
                    @productsSelected="onProductSelected(item, $event)"
                    @deleteProduct="$emit('deleteProduct', {product: $event, categoryId: item.id})"
                />
            </template>
            <template #bottom />
        </v-data-table>

        <product-create
            v-if="createProductIn !== null"
            :category-id="createProductIn"
            @cancel="createProductIn = null"
        />
    </article>
</template>

<script>
import ProductGrid from '../../products/list/ProductGrid';
import CategoryItemTitle from './CategoryItemTitle';
import ProductCreate from '../../products/ProductCreateForm.vue';
import { mapState } from 'vuex';

export default {
    name: 'CategoriesGrid',

    components: {
        ProductCreate,
        CategoryItemTitle,
        ProductGrid,
    },

    props: {
        list: {
            type: Array,
            required: true,
        },
        isAdmin: {
            type: Boolean,
            default: false,
        },

        selected: {
            type: Object,
            required: true,
        },
        initialExpand: {
            type: String,
            default: '',
        },
    },
    emits: ['rowSelected', 'deleteProduct', 'deleteCategory'],

    data() {
        return {
            expanded: [],
            createProductIn: null,
        };
    },

    computed: {
        headers() {
            return [
                { title: '', value: null, class: 'checkboxColumn'},
                { title: this.$t('productGroup'), value: 'title' },
                this.isReviewer || this.isAdmin ? { title: this.$t('modificationDate'), value: null } : null,
                { title: this.isReviewer || this.isAdmin ? this.$t('publishedDateAdmin') : this.$t('publishedDate'), value: null},
                this.isAdmin ? { title: this.$t('productStatus'), value: null, align: 'center' } : null,
                { title: this.$t('products'), value: 'productsCount', align: 'end' },
            ].filter((header) => header !== null);
        },
        ...mapState('auth', ['isReviewer']),
    },

    watch: {
        list() {
            if (this.list?.length && this.initialExpand) {
                this.expanded.push(this.initialExpand);
                setTimeout(() => {
                    const element = this.$refs['initialExpand'];
                    const top = element ? element.offsetTop : 0;
                    window.scroll({top: top, left:0, behavior: 'smooth'});
                }, 500);
            }
        }
    },

    methods: {
        checkIfInitial(item) {
            if (item.id === this.initialExpand) {
                return {
                    ref: 'initialExpand'
                };
            }
        },
        onProductSelected(category, products) {
            this.$emit('rowSelected', category.id, products);
        },
    },
};
</script>

<style scoped lang="stylus">
.titleColumn {
    font-weight: bold;
}

.expandIcon {
    line-height: 16px; // empirical value
}
</style>
