import { mapState } from 'vuex';

export function changeLossPreventer(storeNs) {
    return {
        computed: {
            ...mapState(storeNs, [
                'isDirty',
            ]),
        },

        mounted() {
            window.addEventListener('beforeunload', this.onWindowUnload);
        },

        destroyed() {
            window.removeEventListener('beforeunload', this.onWindowUnload);
        },

        methods: {
            onWindowUnload(event) {
                if (this.isDirty) {
                    event.preventDefault();

                    return event.returnValue = this.$t('confirmDiscard');
                }
            },

            routeGuard(to, from, next) {
                if (this.isDirty) {
                    if (confirm(this.$t('confirmDiscard'))) {
                        next();
                    } else {
                        next(false);
                    }
                } else {
                    next();
                }
            },
        },

        beforeRouteUpdate(to, from, next) {
            this.routeGuard(to, from, next);
        },

        beforeRouteLeave(to, from, next) {
            this.routeGuard(to, from, next);
        },
    };
}
