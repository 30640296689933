import { uploadImage } from '../../../store/shared/uploadImage';

export const dispatchColumnContent = {
    methods: {
        async dispatchColumnContent(payload) {
            if (payload.type === 'image') {
                if (payload.content !== '') {
                    const imgUrl = await uploadImage(payload.content); // perform upload
                    payload.content = imgUrl;
                }
                this.setColumnContent(payload);
            } else {
                this.setColumnContent(payload);
            }
        },
    },
};
