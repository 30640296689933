/**
 *
 * @param {TColumnType} type
 * @return {IColumn}
 */
export function columnFactory(type) {
    return {
        type,
        content: type === 'grid' ? null : '',
    };
}
