import { api } from '../api.js';
import { AuditLogResource } from './AuditLogResource.js';

const auditLogResource = new AuditLogResource(api, 'AuditLog');

export const actions = {
    async getAuditLog(context, filter) {
        const response = await auditLogResource.list(filter);
        return response.data;
    },

    async generateDiff(context, id) {
        const response = await auditLogResource.generateDiff(id);
        return response.data;
    },
};
