<template>
    <div v-if="!readonly">
        <v-tooltip :text="$t('addingTagTooltipInfo')">
            <template v-slot:activator="{ props }">
                <v-icon v-bind="props">info</v-icon>
            </template>
        </v-tooltip>
        <v-combobox
            :model-value="tagName"
            :items="tags"
            :label="$t('tags')"
            :loading="tagsFetching || isAdding"
            item-title="name"
            item-value="id"
            prepend-icon="label"
            @update:model-value="onTagInput"
            @keydown="onKeyDown"
            @click:prepend="onPrependIconClick"
        />
    </div>
    <div v-if="readonly">
        <div>
            <v-label>{{$t('tags')}}</v-label>
        </div>
        <div>
            <span>{{tagName}}</span>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex';

export default {
    name: 'CategoryTag',

    props: {
        category: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean
        }
    },
    emits: ['input'],

    computed: {
        ...mapState('tags', {
            tags: 'list',
            tagsFetching: 'isFetching',
            tagName(state) {
                const tag = state.list.find(tag => tag.id === this.category.categoryId);
                if (tag) {
                    return tag.name;
                } else {
                    return '';
                }
            },
        }),
    },

    data() {
        return {
            newTagName: '',
            isAdding: false,
        }
    },

    async mounted() {
        await this.listTags();
    },

    methods: {
        ...mapActions('tags', {
            listTags: 'list',
            create: 'create',
        }),
        ...mapMutations('tags', ['addToList']),

        onTagInput(value) {
            if (!value) return;

            if (typeof value === 'string') {
                this.newTagName = value;
                return;
            }

            this.$emit('input', value.id);
        },
        onKeyDown(event) {
            if (event.code !== 'Enter') return;
            if (!this.newTagName) return;

            // Do not add tag that already exists
            const ix = this.tags.findIndex(x => x.name === this.newTagName);
            if (ix > -1) return;

            this.isAdding = true;
            this.create({name: this.newTagName}).then(newTag => {
                this.isAdding = false;
                this.newTagName = '';
                this.$emit('input', newTag.id);
            })
        },

        onPrependIconClick() {
            this.$router.push({name: 'tags'});
        },
    },
};
</script>

<style scoped>

</style>
