<template>
    <grid-dimension-chooser
        v-if="content === null"
        @setGridDimensions="onSetGridDimensions"
    />
    <article v-else class="columnEditorGrid">
        <grid-editor-category
            v-if="!readOnly"
            :content="content"
            @input="$emit('input', $event)"
        />

        <grid-editor-product
            v-if="readOnly"
            :content="content"
            :column-index="columnIndex"
            :block-uuid="uuid"
            @input="$emit('input', $event)"
        />
    </article>
</template>

<script>
import GridDimensionChooser from './GridDimensionChooser';
import GridEditorCategory from './GridEditorCategory';
import GridEditorProduct from './GridEditorProduct';

import { columnEditorProps } from '../columnEditorProps';
import { createGridRow } from './createGridRow';

export default {
    name: 'ColumnEditorGrid',

    components: {
        GridEditorProduct,
        GridEditorCategory,
        GridDimensionChooser,
    },

    mixins: [
        columnEditorProps(Object, false),
    ],

    props: {
        content: { // eslint-disable-line vue/require-prop-types
            default: null,
            required: false,
        },
    },
    emits: ['input'],

    methods: {
        onSetGridDimensions(dimensions) {
            const grid = [];
            for (let i = 0; i < dimensions.rows; i++) {
                grid.push(createGridRow(dimensions.cols));
            }

            this.$emit('input', {
                ...dimensions,
                grid,
                headers: createGridRow(dimensions.cols, this.$t('newColumn')),
                caption: this.$t('tableCaption'),
            });
        },
    },
};
</script>

<style scoped lang="stylus">
.columnEditorGrid {
    pointer-events: all;
    width: 100%;
    margin: 0 10px;
}
</style>
