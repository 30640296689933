import { RevisionResource } from '../shared/RevisionResource';

export class CategoryResource extends RevisionResource {
    listWithProducts(filter = {}, parentId = null) {
        return this.api({
            method: 'get',
            url: `${this.getUrl(null, parentId)}/WithAllProducts`,
            params: filter,
        });
    }

    listWithPublishedProducts(filter = {}, parentId = null) {
        return this.api({
            method: 'get',
            url: `${this.getUrl(null, parentId)}/WithAllProducts?forcePublished=true`,
            params: filter,
        });
    }
}
