<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        min-width="290px"
    >
        <template #activator="{ props }">
            <v-text-field
                v-bind="props"
                :model-value="dateFormated"
                :label="label"
                append-inner-icon="event"
                clear-icon="clear"
                persistent-clear
                readonly
                clearable
                @click:clear="onClear"
                variant="underlined"
            />
        </template>
        <v-date-picker show-adjacent-months hide-header v-model="date" @update:modelValue="dateChanged" />
    </v-menu>

</template>

<script>
import { datePickerLocale } from './datePickerLocale';

export default {
    name: 'DatePicker',

    mixins: [
        datePickerLocale,
    ],

    props: {
        modelValue: {
            type: Date,
            required: false,
            default: undefined,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
    },
    emits: ['update:modelValue'],

    data() {
        return {
            menu: false,
            date: this.modelValue
        };
    },
    computed: {
        dateFormated() {
            return this.date?.toLocaleDateString('nb-NO');
        }
    },
    methods: {
        dateChanged() {
            this.$emit('update:modelValue', this.date);
            this.menu = false;
        },

        onClear() {
            this.date = undefined;
            this.$emit('update:modelValue', undefined);
        },
    },
};
</script>

<style scoped>

</style>
