<template>
    <item-title-cell :link-url="linkUrl" :is-admin="isAdmin" :is-deleted="item.isDeleted">
        <router-link
            v-if="!isAdmin && item.wasPublished"
            :to="linkUrl"
            class="notALink pl-4"
        >
            {{ item.title }}
        </router-link>
        <span v-else class="pl-4">{{ item.title }}</span>
        <template v-if="isAdmin && !item.isDeleted" #appendBtn>
            <v-btn icon variant="flat" @click.stop="$emit('deleteProduct')">
                <v-icon>delete</v-icon>
            </v-btn>
        </template>
        <template v-else-if="!item.isDeleted && isReviewer && item.status !== 'Published'" #appendBtn>
            <v-btn
                variant="outlined"
                @click="onViewDraftClick"
            >
                {{ $t('viewDraft') }}
            </v-btn>
        </template>
    </item-title-cell>
</template>

<script>
import ItemTitleCell from '../../shared/ItemTitleCell';

export default {
    name: 'ProductItemTitle',

    components: {
        ItemTitleCell,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },

        isAdmin: {
            type: Boolean,
            required: true,
        },

        isReviewer: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['deleteProduct'],

    computed: {
        linkUrl() {
            return { name: 'products-id', params: { id: this.item.id } };
        },
    },

    methods: {
        onViewDraftClick() {
            this.$router.push({ name: 'products-id', hash:'#draft', params: {id: this.item.id}});
        },
    },

};
</script>

<style scoped>
.notALink {
    text-decoration: none;
    color: inherit;
}
</style>
