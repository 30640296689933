<template>
    <v-dialog :model-value="show" :scrollable="scrollable" persistent max-width="35%">
        <v-card>
            <v-card-title class="pa-4 bg-primary text-white text-h5 ">
                <slot name="title">{{ $t('confirm') }}</slot>
            </v-card-title>
            <v-card-text>
                <slot name="body" />
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-4">
                <v-spacer />
                <v-btn :disabled="isBusy" variant="flat" @click="$emit('cancel')">
                    <slot name="cancelLabel">{{ $t('cancel') }}</slot>
                </v-btn>
                <slot name="confirmButton">
                    <v-btn :color="confirmColor" :disabled="isBusy" :loading="isBusy" variant="flat" @click="$emit('confirm')">
                        <template v-if="confirmIcon" #prepend>
                            <v-icon>{{ confirmIcon }}</v-icon>
                        </template>
                        <slot name="confirmLabel">{{ $t('delete') }}</slot>
                    </v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ConfirmationDlg',

    props: {
        show: {
            type: Boolean,
            required: true,
        },
        confirmColor: {
            type: String,
            default: 'error',
        },
        confirmIcon: {
            type: String,
            default: 'delete',
        },
        loading: {
            type: Boolean,
            default: null,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['cancel', 'confirm'],

    computed: {
        isBusy() {
            if (this.loading === null) {
                return false;
            } else {
                return this.loading;
            }
        },
    },
};
</script>

<style scoped>

</style>
