<template>
    <article class="categoryContentEditor">
        <h2 class="mb-1">{{ $t('productGroup') }}</h2>

        <section-tabs
            :sections="sections"
            :value="sectionIndex"
            :readonly="readonly"
            @input="onSelectSection"
            @reorderSections="$emit('reorderSections', $event)"
            @addSection="$emit('addSection', $event)"
            @renameSection="$emit('renameSection', $event)"
            @deleteSection="$emit('deleteSection', $event)"
        />
        <section-editor
            v-if="sections.length"
            :section="sections[sectionIndex]"
            :read-only="readonly"
            @appendBlock="onAppendBlock"
            @setColumns="onSetColumns"
            @reorderBlocks="onReorderBlocks"
            @copyBlock="onCopyBlock"
            @deleteBlock="onDeleteBlock"
            @createBlock="onCreateBlock"
            @setColumnType="onSetColumnType"
            @setColumnContent="onSetColumnContent"
            @markBlockGeneral="onMarkBlockGeneral"
        />
        <p v-else class="text-center font-italic">No sections</p>
    </article>
</template>

<script>
import SectionTabs from '../../shared/section/SectionTabs';
import SectionEditor from '../../shared/section/SectionEditor';

export default {
    name: 'CategoryTemplateEditor',

    components: {
        SectionEditor,
        SectionTabs,
    },

    props: {
        sections: {
            type: Array,
            required: true,
        },
        readonly: {
            type: Boolean,
        }
    },
    emits: ['appendBlock', 'reorderBlocks', 'createBlock', 'copyBlock', 'deleteBlock', 'setColumns', 'setColumnType', 'setColumnContent', 'markBlockGeneral', 'deleteSection', 'renameSection', 'addSection', 'reorderSections'],

    data() {
        return {
            sectionIndex: 0,
        };
    },

    methods: {
        onSelectSection(index) {
            this.sectionIndex = index;
        },

        passEvent(type, payload) {
            this.$emit(type, {
                sectionIndex: this.sectionIndex,
                ...payload,
            });
        },

        onAppendBlock(event) {
            this.passEvent('appendBlock', event);
        },

        onReorderBlocks(event) {
            this.passEvent('reorderBlocks', event);
        },

        onCreateBlock(event) {
            this.passEvent('createBlock', event);
        },

        onCopyBlock(event) {
            this.passEvent('copyBlock', event);
        },

        onDeleteBlock(event) {
            this.passEvent('deleteBlock', event);
        },

        onSetColumns(event) {
            this.passEvent('setColumns', event);
        },

        onSetColumnType(event) {
            this.passEvent('setColumnType', event);
        },

        onSetColumnContent(event) {
            this.passEvent('setColumnContent', event);
        },

        onMarkBlockGeneral(event) {
            this.passEvent('markBlockGeneral', event);
        },
    },
};
</script>

<style scoped>

</style>
