<template>
    <v-container fluid>
        <v-row>
            <v-text-field
                :model-value="search"
                :label="$t('search')"
                variant="underlined"
                class="searchField mb-2"
                append-inner-icon="search"
                clear-icon="clear"
                persistent-clear
                clearable
                @update:model-value="onSearchInput"
            />
            <v-switch
                v-if="isAdmin"
                v-model="includeDeleted"
                :label="$t('showDeletedProductsSwitch')"
                density="compact"
                color="primary" />
            <v-spacer />
            <v-menu v-if="isAdmin" :close-on-content-click="false" location="left">
                <template #activator="{ props }">
                    <v-btn v-bind="props" icon color="primary">
                        <v-icon>filter_list</v-icon>
                    </v-btn>
                </template>
                <v-list lines="one" density="compact">
                    <v-list-item :title="$t('showStatuses')" />
                    <v-list-item>
                        <v-switch
                            :label="$t('statusPublished')"
                            color="primary"
                            v-model="filterPublished"
                            density="compact"
                            hide-details
                            class="switch"
                        />
                    </v-list-item>
                    <v-list-item>
                        <v-switch
                            :label="$t('statusWorkInProgress')"
                            color="primary"
                            v-model="filterWorkInProgress"
                            density="compact"
                            hide-details
                            class="switch"
                        />
                    </v-list-item>
                    <v-list-item>
                        <v-switch
                            :label="$t('statusRevisionApproved')"
                            color="primary"
                            v-model="filterRevisionApproved"
                            density="compact"
                            hide-details
                            class="switch"
                        />
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-row>
        <v-row v-if="isAdmin" class="mb-2" justify="space-between">
            <div>
                <v-btn :to="{name: 'addCategory'}" class="listAction mr-2" variant="outlined">
                    <v-icon>add</v-icon>
                    {{ $t('createCategory') }}
                </v-btn>
                <v-btn :disabled="selectedToPublish.length === 0" class="listAction mr-2" variant="outlined" @click="$emit('massPublish', selectedToPublish)">
                    <template #prepend>
                        <v-icon>cloud_upload</v-icon>
                    </template>
                    {{ $t('publish') }} {{ selectedToPublish.length || '' }}
                </v-btn>
                <v-btn :disabled="selectedToApprove.length === 0" class="listAction" variant="outlined" @click="$emit('massApprove', selectedToApprove)">
                    <template #prepend>
                        <v-icon>check_box</v-icon>
                    </template>
                    {{ $t('approve') }} {{ selectedToApprove.length || '' }}
                </v-btn>
            </div>
            <div>
                <v-btn class="bg-primary" @click="openAuditLog"> {{ $t('latestChanges') }}</v-btn>
            </div>
        </v-row>
        <v-row>
            <filter-by-tag
                v-if="!isAdmin"
                class="mb-2"
                :selected-tag="selectedTag"
                @selectedTag="onSelectTag"
            />
        </v-row>
    </v-container>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import debounce from 'debounce';
import FilterByTag from '../../tags/FilterByTag';

export default {
    name: 'CategoriesListManagement',

    components: {
        FilterByTag,
    },

    props: {
        isAdmin: {
            type: Boolean,
            required: true,
        },

        isReviewer: {
            type: Boolean,
            required: true,
        },
        selectedProducts: {
            type: Array,
            required: true,
        },
    },
    emits: ['massApprove', 'massPublish', 'filter'],

    data() {
        const includeDeleted = this.$route.query.includeDeleted?.toLowerCase() === 'true';
        return {
            filterPublished: this.$store.state.filter.showPublished,
            filterWorkInProgress: this.$store.state.filter.showWorkInProgress,
            filterRevisionApproved: this.$store.state.filter.showRevisionApproved,
            includeDeleted: includeDeleted,
        };
    },

    computed: {
        ...mapState('filter', ['search', 'showPublished', 'showWorkInProgress', 'showRevisionApproved', 'selectedTag']),

        selectedToApprove() {
            return this.selectedProducts.filter((product) => product.status === 'WorkInProgress');
        },

        selectedToPublish() {
            return this.selectedProducts.filter((product) => product.status === 'RevisionApproved');
        },
    },

    watch: {
        filterPublished(newValue) {
            this.updateFilter({ showPublished: newValue });
        },
        filterWorkInProgress(newValue) {
            this.updateFilter({ showWorkInProgress: newValue });
        },
        filterRevisionApproved(newValue) {
            this.updateFilter({ showRevisionApproved: newValue });
        },
        includeDeleted(newValue) {
            this.$emit('filter', { includeDeleted: newValue });
        },
    },

    methods: {
        ...mapMutations('filter', ['updateFilter']),

        onSearchInput: debounce(function (value) { // don't use arrow function here
            const search = value ? value.trim() : ''; // when using clear icon, value becomes null
            if (search.length === 0 || search.length > 2) {
                this.updateFilter({
                    search,
                });
            }
        }),
        openAuditLog() {
            this.$router.push({
                name: 'audit-log',
            });
        },

        onSelectTag(tag) {
            this.updateFilter({selectedTag: tag});
        },
    },

};
</script>

<style scoped>
.searchField {
    max-width: 25%;
    margin-right: 25px;
}
.switch {
    padding-left: 10px;
}
</style>
