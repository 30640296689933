<template>
    <v-text-field
        :model-value="value"
        :label="label"
        variant="underlined"
        class="itemTitle print-hidden"
        required
        :readonly="readonly"
        @update:model-value="$emit('input', $event)"
    />
</template>

<script>
export default {
    name: 'ItemTitle',

    props: {
        value: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        readonly: {
            type: Boolean,
        }
    },
    emits: ['input'],
};
</script>

<style scoped>
.itemTitle input {
    font-weight: bold;
    font-size: 1.5em;
}
</style>
