<template>
    <div>
        <v-btn
            :disabled="disabled"
            color="primary"
            @click="onFocus"
        >
            <slot>{{ labelOrDefault }}</slot>
        </v-btn>
        <input
            ref="fileInput"
            :accept="accept"
            :disabled="disabled"
            type="file"
            @change="onFileChange"
        >
    </div>
</template>

<script>
export default {
    name: 'FileUpload',

    props: {
        value: {
            type: [Array, String],
            default: '',
        },
        accept: {
            type: String,
            default: 'image/*',
        },
        label: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['input'],
    data() {
        return {
            filename: '',
        };
    },

    computed: {
        labelOrDefault() {
            return (this.label && this.label?.length > 0) ? this.label : $t('chooseImage');
        },
    },

    watch: {
        value(newFilename) {
            this.filename = newFilename;
        },
    },

    mounted() {
        this.filename = this.value;
    },

    methods: {
        onFocus() {
            if (!this.disabled) {
                this.$refs.fileInput.click();
            }
        },

        onFileChange($event) {
            const files = $event.target.files || $event.dataTransfer.files;

            this.$emit('input', files[0]);

            this.$refs.fileInput.value = null;
        },
    },
};
</script>

<style scoped>
    input[type=file] {
        position: absolute;
        left: -99999px;
    }
</style>
