<!-- eslint-disable vue/no-v-html -->
<template>
    <grid-wrapper
        :content="content"
        class="grid"
    >
        <template #headerCell="{ header }">
            <span
                v-html="extractCellText(header)"
            />
        </template>
        <template #bodyCell="{value, col, row}">
            <span v-if="extractCellText(value)" v-html="extractCellText(value)" />
            <textarea v-else :value="getAppendixValue(row, col)" @input="onInputCell($event, row, col)" />
        </template>
    </grid-wrapper>
</template>

<script>
import { mapMutations } from 'vuex';
import GridWrapper from './GridWrapper';
import { productGridCells } from './productGridCells';
import { extractCellText } from './cellOperations';

export default {
    name: 'GridEditorProduct',

    components: {
        GridWrapper,
    },

    mixins: [
        productGridCells,
    ],

    props: {
        content: {
            type: Object,
            required: true,
        },
        blockUuid: {
            type: String,
            required: true,
        },
        columnIndex: {
            type: Number,
            required: true,
        },
    },

    methods: {
        ...mapMutations('products', ['setGridAppendix']),

        onInputCell(event, row, col) {
            this.setGridAppendix({
                value: event.currentTarget.value,
                uuid: this.blockUuid,
                columnIndex: this.columnIndex,
                row,
                col,
            });
        },

        extractCellText,
    },
};
</script>

<style scoped lang="stylus">
.grid {
    margin: $cellPadding;

    textarea {
        outline: 1px solid transparent;
        transition: all 150ms linear;
        width: 100%;
        height: 100%;
        resize: none;

        &::placeholder {
            font-style: italic;
        }
    }

    td:hover,
    th:hover {
        textarea,
        input {
            outline: 1px solid silver;
            transition: all 150ms linear;
        }
    }
}
</style>
