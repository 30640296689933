<script>
import {defineComponent} from 'vue'
import {formatDateTime} from "../../filters/formatDate";

export default defineComponent({
    name: "ItemDeletedAlert",
    props: {
        deletedAt: {
            type: String,
        },
        deletedBy: {
            type: String,
        }
    },
    methods: {
        formatDateTime,
    },
})
</script>

<template>
    <v-alert color="warning" v-if="!!deletedAt" class="deletedAlert">
        <p>{{ $t('itemDeletedInfo') }}</p>
        <p class="mb-0">{{ $t('itemDeletedDetails', { deletedAt: formatDateTime(deletedAt), deletedBy }) }}</p>
    </v-alert>
</template>

<style scoped>
.deletedAlert {
    margin-bottom: 10px;
}
</style>
