<template>
    <v-btn icon v-ripple variant="flat" @click.stop="$emit('input', !value)">
        <v-icon>{{ value ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
    </v-btn>
</template>

<script>
export default {
    name: 'SmallCheckbox',

    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['input'],
};
</script>

<style scoped>

</style>
