<template>
    <template v-if="category?.visibleProducts?.length">
        <tr v-for="item in category.visibleProducts" :key="item.id" :class="{deleted: item.isDeleted}">
            <td class="checkboxColumn">
                <small-checkbox v-if="isAdmin && !item.isDeleted" :value="isSelected(item.id)" @input="checkboxChanged(item, $event)" />
            </td>
            <td class="titleColumn">
                <product-item-title
                    :is-admin="isAdmin"
                    :is-reviewer="isReviewer"
                    :item="item"
                    @deleteProduct="$emit('deleteProduct', item)"
                />
            </td>
            <td class="dateColumn">
                <div class="placeholder" v-if="!item.isDeleted">{{ formatDate(item.modifiedAt) }}</div>
            </td>
            <td v-if="isAdmin || isReviewer" class="dateColumn">
                <div class="placeholder" v-if="!item.isDeleted">{{ formatDate(item.publishDate) }}</div>
            </td>
            <td v-if="isAdmin" class="statusColumn">
                <div class="placeholder" v-if="!item.isDeleted">{{ $t('status' + item.status) }}</div>
            </td>
            <td class="countColumn"><div class="placeholder" /></td>
        </tr>
    </template>
    <template v-else>
        <tr disabled>
            <td class="text-center" colspan="100">
                {{ $t('noProductsFound') }}
            </td>
        </tr>
    </template>
</template>

<script>
import { mapState } from 'vuex';
import { formatDate } from '../../../filters/formatDate';
import ProductItemTitle from './ProductItemTitle';
import SmallCheckbox from '../../shared/SmallCheckbox';

export default {
    name: 'ProductGrid',

    components: {
        SmallCheckbox,
        ProductItemTitle,
    },

    props: {
        category: {
            type: Object,
            required: true,
        },

        isAdmin: {
            type: Boolean,
            default: false,
        },

        selected: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    emits: ['deleteProduct', 'productsSelected'],

    data() {
        return {
            selectedProducts: this.selected,
        };
    },

    computed: {
        ...mapState('products', ['isFetching']),
        ...mapState('filter', ['search']),
        ...mapState('auth', ['isReviewer']),
    },


    methods: {
        formatDate,
        isSelected(productId) {
            return this.selected?.some(s => s.id === productId);
        },
        checkboxChanged(item, value) {
            if (this.selectedProducts.some(x => x.id === item.id)) {
                this.selectedProducts = this.selectedProducts.filter(x => x.id !== item.id);
            }
            else{
                this.selectedProducts.push(item);
            }

            this.$emit('productsSelected', this.selectedProducts);
        }
    },
};
</script>

<style scoped lang="stylus">
.rowCheckbox {
    padding-top: 0;
}
</style>

<style>
.rowCheckbox .v-input--selection-controls__input {
    margin-right: 0;
}
.rowCheckbox .v-input--checkbox {
}
</style>
