<template>
    <article class="filter-by-tag mb-3">
        <v-btn
            v-bind="buttonAttrs(null)"
            @click="$emit('selectedTag', null)"
        >
            {{ $t('allProducts') }}
        </v-btn>
        <v-btn
            v-bind="buttonAttrs(tag)"
            v-for="tag of list"
            :key="tag.id"
            @click="$emit('selectedTag', tag.id)"
        >
            {{ tag.name }}
        </v-btn>
        <v-btn class="to-right" variant="flat" color="primary" @click="openAuditLog"> {{ $t('latestChanges') }} </v-btn>
    </article>
</template>

<script>
import { mapActions, mapState} from 'vuex';

export default {
    name: 'FilterByTag',

    props: {
        selectedTag: {
            type: String,
            default: null,
        },
    },
    emits: ['selectedTag'],

    computed: {
        ...mapState('tags', ['list', 'isFetching']),
    },

    async mounted() {
        await this.listTags();
    },

    methods: {
        ...mapActions('tags', {
            listTags: 'list',
        }),

        buttonAttrs(tag) {
            let outline = true;

            if (tag === null) {
                outline = this.selectedTag !== null;
            } else {
                outline = tag.id !== this.selectedTag;
            }

            return {
                color: 'primary',
                class: 'ma-0',
                variant: outline ? 'outlined' : 'flat',
            };
        },
        openAuditLog() {
            this.$router.push({
                name: 'audit-log',
            });
        },
    },


};
</script>

<style scoped lang="stylus">
.filter-by-tag {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .to-right {
        margin-left: auto !important;
    }
}

.v-btn {
    border-radius: 0;

    &:not(:first-child) {
        border-left: 1px transparent;
    }

    &:first-child {
        border-radius: 3px 0 0 3px;
    }
    &:last-child {
        border-radius: 0 3px 3px 0;
    }
}
</style>
