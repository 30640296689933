<template>
    <v-btn :to="to" variant="flat" icon @click.stop>
        <v-icon>edit</v-icon>
    </v-btn>
</template>

<script>
export default {
    name: 'EditRowBtn',

    props: {
        to: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>
