function lineBreakToBR(str) {
    return str.replace(/\r?\n/mg, '<br/>');
}

/**
 * @param {TGridCell} cell
 * @return {string}
 */
export function extractCellText(cell) {
    if (typeof cell === 'string') {
        return lineBreakToBR(cell);
    } else {
        return lineBreakToBR(cell.text);
    }
}

export function mergeCell(cell, appendix = '') {
    if (typeof cell === 'string') {
        return cell + appendix;
    } else {
        const {text, ...other} = cell;
        return {
            text: text + appendix,
            ...other,
        };
    }
}

export function setCellText(cell, value) {
    if (typeof cell === 'string') {
        return value;
    } else {
        return {
            ...cell,
            text: value,
        };
    }
}

function cleanCell(cell) {
    if (Object.keys(cell).length === 1) {
        return cell.text;
    } else {
        return cell;
    }
}

export function applyColSpan(cell) {
    if (typeof cell === 'string') {
        return {
            text: cell,
            colSpan: 2,
        };
    } else {
        const colSpan = cell.colSpan || 1;
        return {
            ...cell,
            colSpan: colSpan + 1,
        };
    }
}

export function applyRowSpan(cell) {
    if (typeof cell === 'string') {
        return {
            text: cell,
            rowSpan: 2,
        };
    } else {
        const rowSpan = cell.rowSpan || 1;
        return {
            ...cell,
            rowSpan: rowSpan + 1,
        };
    }
}

export function applySplit(cell) {
    delete cell.colSpan;
    delete cell.rowSpan;

    return cleanCell(cell);
}

export function toggleItalic(cell) {
    if (typeof cell === 'string') {
        return {
            text: cell,
            italic: true,
        };
    } else {
        if (cell.italic) {
            delete cell.italic;
            return cleanCell(cell);
        } else {
            cell.italic = true;
            return cell;
        }
    }
}
export function toggleBold(cell) {
    if (typeof cell === 'string') {
        return {
            text: cell,
            bold: true,
        };
    } else {
        if (cell.bold) {
            delete cell.bold;
            return cleanCell(cell);
        } else {
            cell.bold = true;
            return cell;
        }
    }
}

function insertTag(str, tag, selectionStart, selectionEnd) {
    const startTag = `<${tag}>`;
    const endTag = `</${tag}>`;
    return str.slice(0, selectionStart)
        + startTag
        + str.slice(selectionStart, selectionEnd)
        + endTag
        + str.slice(selectionEnd);
}

function insertTagIntoCell(cell, tag, selectionStart, selectionEnd) {
    if (typeof cell === 'string') {
        return insertTag(cell, tag, selectionStart, selectionEnd);
    } else {
        const text = insertTag(cell.text, tag, selectionStart, selectionEnd);
        return {
            ...cell,
            text,
        };
    }
}

export function insertSup(cell, selectionStart, selectionEnd) {
    return insertTagIntoCell(cell, 'sup', selectionStart, selectionEnd);
}

export function insertSub(cell, selectionStart, selectionEnd) {
    return insertTagIntoCell(cell, 'sub', selectionStart, selectionEnd);
}

function insertChar(str, char, selectionStart, selectionEnd) {
    return str.slice(0, selectionStart)
        + char
        + str.slice(selectionEnd);
}

export function insertCharIntoCell(cell, char, selectionStart, selectionEnd) {
    if (typeof cell === 'string') {
        return insertChar(cell, char, selectionStart, selectionEnd);
    } else {
        const text = insertTag(cell.text, char, selectionStart, selectionEnd);
        return {
            ...cell,
            text,
        };
    }
}
