import {
    setCategory,
    setProduct,
    setClean,
} from './types';

export const mutations = {
    [setCategory](state, category) {
        state.category = category;
    },
    [setProduct](state, product) {
        state.product = product;
    },
    [setClean](state) {
        state.category = null;
        state.product = null;
    },
};
