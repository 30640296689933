import axios from 'axios';
import {adalWrapper} from './adalWrapper.js';

export const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

function onFullFilled(config) {
    return adalWrapper.acquireToken()
        .then((token) => {
            config.headers.setAuthorization(`Bearer ${token}`);
            return Promise.resolve(config);
        });
}

function onRejected(error) {
    if (error.response) {
        if (error.response.status === 401) {
            adalWrapper.login();
        }
        if (error.response.status === 403) {
            throw error.response.data;
        }
    }
}

api.interceptors.request.use(
    onFullFilled,
    onRejected
);
