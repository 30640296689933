<template>
    <article class="columnEditorImage">
        <img v-if="content" :src="content" class="image">
        <v-row no-gutters class="buttonsWrapper">
            <v-btn v-if="content" @click="$emit('input', '')">
                <v-icon>delete</v-icon>
            </v-btn>
            <image-upload @input="onFileSelected">{{ buttonLabel }}</image-upload>
        </v-row>
    </article>
</template>

<script>
import ImageUpload from './ImageUpload';
import { columnEditorProps } from '../columnEditorProps';

export default {
    name: 'ColumnEditorImage',

    components: {
        ImageUpload,
    },

    mixins: [
        columnEditorProps(),
    ],
    emits: ['input'],

    data() {
        return {
            imgSrc: this.content,
        };
    },

    computed: {
        buttonLabel() {
            if (this.content) {
                return this.$t('replaceImage');
            } else {
                return this.$t('chooseImage');
            }
        },
    },

    methods: {
        onFileSelected(file) {
            this.$emit('input', file);
        },
    },
};
</script>

<style scoped>
.image {
    width: 100%;
}

.buttonsWrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
}
</style>
