import { productFilter } from './productFilter';
import { compareStrings, productSorter } from './productSorter.js';

export const getters = {
    /**
     * @param {IFilterState} state
     */
    allowedStatuses(state) {
        const statuses = [];

        if (state.showPublished) {
            statuses.push('Published');
        }
        if (state.showRevisionApproved) {
            statuses.push('RevisionApproved');
        }
        if (state.showWorkInProgress) {
            statuses.push('WorkInProgress');
        }

        return statuses;
    },

    filterActive(state, getters) {
        const searchActivated = state.search.length > 0;
        const statusesActivated = getters.allowedStatuses.length !== 3;

        return searchActivated || statusesActivated;
    },

    /**
     * @param {IFilterState} state
     * @param {typeof getters} getters
     * @param {object} rootState
     * @return {IFilteredCategory[]}
     */
    visibleCategoriesUser(state, getters, rootState) {
        /** @type IFullCategory[] */ const categories = rootState.categories.fullList;
        const filter = {
            search: state.search,
            allowedStatuses: getters.allowedStatuses,
        };

        return [...categories]
            .reduce((filtered, category) => {
                if (category.categoryId === state.selectedTag || state.selectedTag === null) {
                    const visibleProducts = productFilter(category.products, filter);

                    if (visibleProducts.length > 0 || !getters.filterActive) {
                        filtered.push({
                            ...category,
                            visibleProducts: visibleProducts.sort(productSorter),
                        });
                    }
                }

                return filtered;
            }, [])
            .sort((catA, catB) => {
                return compareStrings(catA.title, catB.title);
            });
    },

    /**
     * @param {IFilterState} state
     * @param {typeof getters} getters
     * @param {object} rootState
     * @return {IFilteredCategory[]}
     */
    visibleCategoriesAdmin(state, getters, rootState) {
        const categories = rootState.categories.fullList;
        const filter = {
            search: state.search,
            allowedStatuses: getters.allowedStatuses,
        };

        return [...categories]
            .reduce((filtered, category) => {
                const visibleProducts = productFilter(category.products, filter);

                if (visibleProducts.length > 0 || !getters.filterActive) {
                    filtered.push({
                        ...category,
                        visibleProducts: visibleProducts.sort(productSorter),
                    });
                }

                return filtered;

            }, [])
            .sort((catA, catB) => {
                return compareStrings(catA.title, catB.title);
            });
    },
};
