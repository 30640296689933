export function canSaveCurrent(state) {
    /** @type IItem */
    const current = state.current;

    if (!current) {
        return false;
    }

    let result = true;

    if (current.title === '') {
        result = false;
    } else {
        for (const section of current.sections) {
            if (section.title === '') {
                result = false;
                break;
            }
        }
    }

    return result;
}
