/**
 * @param {Array<IProduct>} list
 * @param {object} filter
 * @return {Array<IProduct>}
 */
export function productFilter(list, filter) {
    const searchStr = filter.search.toLowerCase();

    return list.filter((item) => {
        const titleOk = searchStr.length ? item.title.toLowerCase().includes(searchStr) : true;
        const statusOk = filter.allowedStatuses.length ? filter.allowedStatuses.includes(item.status) : false;
        return titleOk && statusOk;
    });
}
