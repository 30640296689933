/**
 * CRUD wrapper for REST-like API
 * Supports nested resources
 */
export class Resource {
    /**
     * @param {AxiosInstance} api
     * @param {string} resource
     * @param {Resource} parent
     */
    constructor(api, resource, parent = null) {
        this.api = api;
        this.resource = resource;

        if (parent) {
            this.parent = parent;
        }
    }

    /**
     * @protected
     * @param {number} [id=null]
     * @param {number} [parentId=null]
     */
    getUrl(id = null, parentId = null) {
        let url = this.resource;

        if (id !== null) {
            url = `${url}/${id}`;
        }

        if (this.parent && parentId !== null) {
            url = `${this.parent.getUrl(parentId)}/${url}`;
        }

        return url.replace(/\/\//mg, '/');
    }

    list(filter = {}, parentId = null) {
        return this.api({
            method: 'get',
            url: this.getUrl(null, parentId),
            params: filter,
        });
    }

    create(data, parentId = null) {
        return this.api({
            method: 'put',
            url: this.getUrl(null, parentId),
            data,
        });
    }

    read(id, parentId = null) {
        return this.api({
            method: 'get',
            url: this.getUrl(id, parentId),
        });
    }

    update(data, parentId = null) {
        return this.api({
            method: 'post',
            url: this.getUrl(data.id, parentId),
            data,
        });
    }

    delete(id, parentId = null) {
        return this.api({
            method: 'delete',
            url: this.getUrl(id, parentId),
        });
    }

    patch({id, ...patch}, parentId = null) {
        return this.api({
            method: 'patch',
            url: this.getUrl(id, parentId),
            data: patch,
        });
    }
}
