<template>
    <article class="blockActions">
        <context-action-btn v-if="!readOnly" icon="add" @click="$emit('add')">{{ $t('addBlockTooltip') }}</context-action-btn>

        <context-action-btn v-if="!readOnly" icon="content_copy" @click="$emit('copy')">{{ $t('copyBlockTooltip') }}</context-action-btn>

        <context-action-btn v-if="!readOnly" icon="delete" @click="$emit('delete')">{{ $t('deleteBlockTooltip') }}</context-action-btn>

        <context-action-btn v-if="isEmbedded" icon="remove_circle" @click="$emit('unembed')">{{ $t('unembedBlockTooltip') }}</context-action-btn>

        <v-tooltip location="right">
            {{ $t('markBlockToggledTooltip') }}
            <template #activator="{ props }">
                <v-switch v-if="readOnly && !isEmbedded" v-bind="props" color="white" :model-value="overridden" class="switch" @click="$emit('markBlockToggled', !overridden)" />
            </template>
        </v-tooltip>

        <v-tooltip location="right">
            {{ $t('markBlockGeneralTooltip') }}
            <template #activator="{ props }">
                <v-switch v-if="!readOnly" v-bind="props" color="white" :model-value="isGeneralInfo" class="switch" @click="$emit('markBlockGeneral', !isGeneralInfo)" />
            </template>
        </v-tooltip>
    </article>
</template>

<script>
import ContextActionBtn from '../ContextActionBtn';

export default {
    name: 'BlockActions',

    components: {
        ContextActionBtn,
    },

    props: {
        readOnly: {
            type: Boolean,
            required: true,
        },

        overridden: {
            type: Boolean,
            required: true,
        },

        isGeneralInfo: {
            type: Boolean,
            default: false,
        },

        isEmbedded: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['markBlockGeneral', 'markBlockToggled', 'unembed', 'delete', 'copy', 'add'],
};
</script>

<style scoped lang="stylus">
.blockActions {
    background-color: #2e73b6;
}

.switch {
    margin-left: 6px;
}
</style>
