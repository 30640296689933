import { RevisionResource } from '../shared/RevisionResource.js';

export class ProductResource extends RevisionResource {
    readPublished(id, parentId = null) {
        return this.api({
            method: 'get',
            url: `${this.getUrl(id, parentId)}/published`,
        });
    }
}
