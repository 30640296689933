import { createApp } from 'vue';
import App from './App.vue';
import {router} from './router';
import store from '../store';
import {i18n} from './i18n';
import { adalWrapper } from '../store/adalWrapper.js';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import * as labsComponents from 'vuetify/labs/components';
import { aliases, md } from 'vuetify/iconsets/md';
import '../styles/shared.styl';

const vuetify = createVuetify({
    components: {
        ...components,
        ...labsComponents,
    },
    directives,
    theme: {
        primary: '#2e73b6',
    },
    icons: {
        defaultSet: 'md',
        aliases,
        sets: {
            md,
        },
    },
});

const app = createApp({
    ...App
});

app.use(router);
app.use(vuetify);
app.use(store);
app.use(i18n);

adalWrapper.init()
    .then(() => {
        return store.dispatch('auth/getIsAdmin');
    })
    .then(() => {
        app.mount('#app');
    });

