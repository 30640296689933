import { createI18n } from 'vue-i18n';
import no from './assets/i18n/no';

const LANG_STORAGE_KEY = 'basal-lang';
const langStorage = {
    set(lang) {
        sessionStorage.setItem(LANG_STORAGE_KEY, lang);
    },
    get() {
        return sessionStorage.getItem(LANG_STORAGE_KEY);
    },
};

export const availableLanguages = ['no', 'en'];
const loadedLanguages = ['no'];

export const i18n = createI18n({
    locale: langStorage.get() || 'no',
    fallbackLocale: 'no',
    silentFallbackWarn: process.env.NODE_ENV !== 'development',
    messages: {
        no,
    },
});

function setI18nLanguage(lang) {
    i18n.locale = lang;
    langStorage.set(lang);
    document.documentElement.setAttribute('lang', lang);

    return lang;
}

function checkLang(languageCode) {
    const [lang] = languageCode.split('-');
    if (availableLanguages.includes(lang)) {
        return lang;
    } else {
        return false;
    }
}

function detectBrowserLanguage() {
    const langs = navigator.languages || [navigator.language];

    for (const browserLang of langs) {
        const lang = checkLang(browserLang);
        if (lang) {
            return lang;
        }
    }

    return availableLanguages[0];
}

function getPreferredLanguage() {
    let lang = langStorage.get();

    if (!lang) {
        lang = detectBrowserLanguage();
        langStorage.set(lang);
    }

    return lang;
}

export function loadLanguageAsync(lang) {
    if (i18n.locale !== lang || !loadedLanguages.includes(lang)) {
        if (!loadedLanguages.includes(lang) && availableLanguages.includes(lang)) {
            // eslint-disable-next-line prefer-template
            return import('./assets/i18n/' + lang).then((msgs) => {
                i18n.global.setLocaleMessage(lang, msgs.default);
                loadedLanguages.push(lang);
                return setI18nLanguage(lang);
            });
        }
        return Promise.resolve(setI18nLanguage(lang));
    }

    return Promise.resolve(lang);
}

const preferredLang = getPreferredLanguage();
loadLanguageAsync(preferredLang);
