import { Resource } from './Resource';

export class RevisionResource extends Resource {
    readRevision(id, revision, parentId = null) {
        return this.api({
            method: 'get',
            url: this.getUrl(id, parentId),
            params: {
                revision,
            },
        });
    }

    approve(id, revision, productGroupRevision, parentId = null) {
        return this.api({
            method: 'post',
            url: `${this.getUrl(id, parentId)}/approve?productGroupRevision=${productGroupRevision}`,
            params: {
                revision,
            },
        });
    }

    publish(id, revision, parentId = null) {
        return this.api({
            method: 'post',
            url: `${this.getUrl(id, parentId)}/publish`,
            params: {
                revision,
            },
        });
    }

    bulkApprove(productsToApprove) {
        return this.api({
            method: 'post',
            url: `${this.getUrl()}/approve`,
            data: productsToApprove,
        });
    }

    bulkPublish(productsToPublish) {
        return this.api({
            method: 'post',
            url: `${this.getUrl()}/publish`,
            data: productsToPublish,
        });
    }
}
