import AuthenticationContext from 'adal-angular';
import { getRootUrl } from './getRootUrl';
import store from './index';

export const adalWrapper = (function () {
    const config = {
        tenant: process.env.VUE_APP_TENANT_ID,
        clientId: process.env.VUE_APP_CLIENT_ID,
        cacheLocation: 'localStorage',
    };

    let authContext;

    return {
        init() {
            config.redirectUri = getRootUrl();

            authContext = new AuthenticationContext(config);

            return new Promise((resolve) => {
                if (authContext.isCallback(window.location.hash) || (window.self !== window.top && !location.pathname.startsWith('/print'))) {
                    // redirect to the location specified in the url params.
                    authContext.handleWindowCallback();
                } else {
                    // try pull the user out of local storage
                    const user = authContext.getCachedUser();

                    if (user) {
                        if (user.profile.exp * 1000 < Date.now()) {
                            this.login();
                        } else {
                            store.commit('auth/login', this.getUserProfile());
                            resolve();
                        }
                    } else {
                        // no user at all - go sign in.
                        this.login();
                    }
                }
            });
        },

        /**
         * A promise that resolves to an ADAL token for resource access
         */
        acquireToken() {
            return new Promise((resolve, reject) => {
                authContext.acquireToken(config.clientId, (errorDesc, token) => {
                    if (errorDesc || !token) {
                        store.commit('auth/logout');
                        return reject(errorDesc);
                    } else {
                        return resolve(token);
                    }
                });
            });
        },

        /**
         * Issue an interactive authentication request for the current user and the api resource.
         */
        acquireTokenRedirect() {
            authContext.acquireTokenRedirect(config.clientId);
        },

        /**
         * Indicates if there is a valid, non-expired access token present in localStorage.
         */
        isAuthenticated() {
            // getCachedToken will only return a valid, non-expired token.
            if (authContext.getCachedToken(config.clientId)) {
                return true;
            } else {
                store.commit('auth/logout');
                return false;
            }
        },

        getUserProfile() {
            const cachedUser = authContext.getCachedUser();

            if (cachedUser) {
                return cachedUser.profile;
            } else {
                return null;
            }
        },

        login() {
            authContext.login();
        },

        logout() {
            authContext.logOut();
        },
    };
}());
