import { commonMutations } from '../shared/commonMutations';
import { addToList } from './types';

export const mutations = {
    ...commonMutations,

    [addToList](state, {id, name}) {
        state.list.push({id, name});
    },
};
