<template>
    <article v-if="category" class="centerBlock">
        <v-progress-linear color="primary" :active="isFetching" />
        <item-deleted-alert :deleted-at="category.deletedAt" :deleted-by="category.deletedBy" />
        <item-title
            :value="category.title"
            :label="$t('categoryCreationTitle')"
            :readonly="!!category.deletedAt"
            @input="onTitleInput" />

        <category-tag :category="category" class="tagEditor" @input="onTagInput" :readonly="!!category.deletedAt" />

        <category-item-management
            :category="category"
            :can-save="canSave"
            @save="onSaveClick"
            @approve="onApproveClick"
            @revertTo="revision = $event"
        />

        <category-content-editor
            :sections="category.sections"
            :readonly="!!category.deletedAt"
            @reorderSections="reorderSections"
            @reorderBlocks="reorderBlocks"
            @addSection="addSection"
            @renameSection="renameSection"
            @deleteSection="deleteSection"
            @appendBlock="appendBlock"
            @setColumns="setColumns"
            @copyBlock="copyBlock"
            @deleteBlock="deleteBlock"
            @createBlock="createBlock"
            @setColumnType="setColumnType"
            @setColumnContent="dispatchColumnContent"
            @markBlockGeneral="markBlockGeneral"
        />
        <router-view />
        <confirmation-dlg
            :show="showApproveDlg"
            :loading="isFetching"
            confirm-color="primary"
            confirm-icon="check_box"
            @confirm="onConfirmApprove"
            @cancel="showApproveDlg = false"
        >
            <template #body>{{ $t('categoryApproveConfirmationBody') }}</template>
            <template #cancelLabel>{{ $t('no') }}</template>
            <template #confirmLabel>{{ $t('yes') }}</template>
        </confirmation-dlg>

        <error-notification :error="error" />
    </article>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import CategoryContentEditor from './CategoryContentEditor';
import CategoryItemManagement from './CategoryItemManagement';
import ConfirmationDlg from '../../shared/ConfirmationDlg';
import ItemTitle from '../../shared/ItemTitle';
import ErrorNotification from '../../shared/ErrorNotification';
import { approvable } from '../../shared/approvable';
import { dispatchColumnContent } from '../../shared/column/dispatchColumnContent';
import { revisionedEntity } from '../../shared/revisionedEntity';
import CategoryTag from './CategoryTag';
import { changeLossPreventer } from '../../shared/changeLossPreventer.js';
import ItemDeletedAlert from "@/shared/ItemDeletedAlert.vue";

export default {
    name: 'CategoryItemEdit',

    components: {
        ItemDeletedAlert,
        CategoryTag,
        ErrorNotification,
        ItemTitle,
        ConfirmationDlg,
        CategoryItemManagement,
        CategoryContentEditor,
    },

    mixins: [
        approvable,
        dispatchColumnContent,
        changeLossPreventer('categories'),
        revisionedEntity('categories'),
    ],

    props: {
        id: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapState('categories', {
            category: 'current',
            categories: 'list',
            isFetching: 'isFetching',
            isDirty: 'isDirty',
            error: 'error',
        }),

        ...mapGetters('categories', ['canSaveCurrent']),

        ...mapState('tags', {isTagsFetching: 'isFetching'}),

        canSave() {
            return this.canSaveCurrent && !this.isFetching && !this.isTagsFetching;
        },
    },

    watch: {
        category(newVal) {
            this.setNavCategory(newVal);
            this.setNavProduct(null);
        },
    },

    async mounted() {
        await this.read(this.id);
    },

    methods: {
        ...mapActions('categories', ['read', 'update', 'approveCurrent']),
        ...mapMutations('categories', [
            'updateCurrent',
            'setCurrent',
            'renameSection',
            'addSection',
            'deleteSection',
            'reorderSections',

            'appendBlock',
            'setColumns',
            'reorderBlocks',
            'copyBlock',
            'deleteBlock',
            'createBlock',

            'setColumnType',
            'setColumnContent',
            'markBlockGeneral',
            'setDirty',
        ]),
        ...mapMutations('nav', {
            setNavCategory: 'setCategory',
            setNavProduct: 'setProduct',
        }),

        onTitleInput(value) {
            this.updateCurrent({title: value});
        },

        onTagInput(value) {
            this.updateCurrent({categoryId: value});
        },

        async onSaveClick() {
            await this.update(this.category);

            this.setDirty(false);
            this.$router.push({ name: 'categories-id', params: {id: this.id} });
        },

        async updateEntity() {
            await this.update(this.category);
        },
    },
};
</script>

<style scoped lang="stylus">
.tagEditor {
    z-index: 10;
}
</style>
