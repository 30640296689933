import { dirtyFlagPluginFactory } from '../shared/dirtyFlagPluginFactory';

import * as sectionTypes from '../shared/sections/sectionTypes';
import * as blockTypes from '../shared/blocks/blockTypes';
import * as columnTypes from '../shared/columns/columnTypes';

export const dirtyCategoryFlag = dirtyFlagPluginFactory('categories', [
    ...Object.keys(sectionTypes),
    ...Object.keys(blockTypes),
    ...Object.keys(columnTypes),
]);
