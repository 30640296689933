/**
 * @return {ICategoryState}
 */
export function state () {
    const defaultState = {
        isFetching: false,
        error: null,

        list: [
        ],
        fullList: [],

        current: null,
        isDirty: false,

        searchHitsById: {

        },
    };

    return defaultState;
}
