import { addSection, deleteSection, renameSection, reorderSections } from './sectionTypes';
import { sectionFactory } from './sectionFactory';
import { reorderArray } from '../reorderArray';

/**
 * @param category
 * @param {string} id
 * @return {ISection | undefined}
 */
function getSectionById(category, id) {
    return category.sections.find((section) => section.id === id);
}

/**
 * @param category
 * @param id
 * @return {number}
 */
function getSectionIndexById(category, id) {
    return category.sections.findIndex((section) => section.id === id);
}

export const sectionMutations = {
    [reorderSections](state, { oldIndex, newIndex }) {
        const sections = state.current.sections;
        reorderArray(sections, { oldIndex, newIndex });
    },

    [renameSection](state, { id, title }) {
        const section = getSectionById(state.current, id);
        if (section) {
            section.title = title;
        }
    },

    [addSection](state, {title}) {
        const section = sectionFactory({title});

        state.current.sections.push(section);
    },

    [deleteSection](state, id) {
        const index = getSectionIndexById(state.current, id);

        if (index > -1) {
            state.current.sections.splice(index, 1);
        }
    },
};
