const endNumberRe = /\d+$/im;

/**
 * @param {string} productName
 * @return {number|null}
 */
function extractEndNumber(productName) {
    const match = productName.match(endNumberRe);
    if (match && match.length) {
        const number = parseInt(match[0], 10);
        if (isNaN(number)) {
            return null;
        } else {
            return number;
        }
    } else {
        return null;
    }
}

function extractNameAndNumber(productName) {
    let name = productName;
    const number = extractEndNumber(productName);

    if (number !== null) {
        name = name.replace(endNumberRe, '').trim();
    }

    return {
        name,
        number,
    };
}

export function compareStrings(a, b) {
    const aTrimmed = a.trim().toLocaleLowerCase();
    const bTrimmed = b.trim().toLocaleLowerCase();

    if (aTrimmed > bTrimmed) {
        return 1;
    } else if (aTrimmed < bTrimmed) {
        return  -1;
    } else {
        return 0;
    }
}

/**
 * @param {IProduct} productA
 * @param {IProduct} productB
 * @return {number}
 */
export function productSorter(productA, productB) {
    const a = extractNameAndNumber(productA.title);
    const b = extractNameAndNumber(productB.title);

    if (a.name.toLocaleLowerCase() === b.name.toLocaleLowerCase()) {
        if (a.number !== null && b.number !== null) {
            return a.number - b.number;
        } else {
            return compareStrings(productA.title, productB.title);
        }
    } else {
        return compareStrings(a.name, b.name);
    }
}
