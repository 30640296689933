import {
    createEmpty,
    deleteProduct,
    setFullList,
} from './types';
import { commonMutations } from '../shared/commonMutations';
import { sectionMutations } from '../shared/sections/sectionMutations';
import { blockMutations } from '../shared/blocks/blockMutations';
import { columnMutations } from '../shared/columns/columnMutations';
import { categoryFactory } from './categoryFactory';
import { dirtyMutations } from '../shared/dirty/dirtyMutations';
import { deleteFromList } from '../shared/commonTypes';

export const mutations = {
    ...commonMutations,

    [createEmpty](state) {
        state.current = categoryFactory();
    },

    ...sectionMutations,

    ...blockMutations,

    ...columnMutations,

    ...dirtyMutations,

    [setFullList](state, fullList) {
        state.fullList = fullList;
    },

    [deleteProduct](state, {categoryId, productId}) {
        const category = state.fullList.find(category => category.id === categoryId);
        if (!category) return;

        const productIndex = category.products.findIndex(product => product.id === productId);
        if (productIndex === -1) return;

        category.products[productIndex].isDeleted = true;
    },

    [deleteFromList](state, id) {
        commonMutations.deleteFromList(state, id);

        const category = state.fullList.find(category => category.id === id);
        if (!category) return;

        category.isDeleted = true;
        category.products.forEach(p => {
            p.isDeleted = true;
        })
    },
};
