<template>
    <article class="columnType d-flex justify-center align-center fill-height">
        <v-btn size="large" icon class="bg-primary typeBtn" @click="$emit('setColumnType', 'image')">
            <v-icon>insert_photo</v-icon>
        </v-btn>
        <v-btn size="large" icon class="bg-primary typeBtn" @click="$emit('setColumnType', 'text')">
            <v-icon>text_format</v-icon>
        </v-btn>
        <v-btn size="large" class="bg-primary typeBtn" icon @click="$emit('setColumnType', 'grid')">
            <v-icon>view_module</v-icon>
        </v-btn>
    </article>
</template>

<script>
export default {
    name: 'ColumnType',
    emits: ['setColumnType'],
};
</script>

<style scoped>
.columnType {
    background-color: #e8e8e8;
    gap: 16px;
}
.typeBtn {
    width: 50px;
    height: 50px;
    background-color: #2e73b6;
    color: white;
}
</style>
