import auth from './auth';

import categories from './categories';
import { dirtyCategoryFlag } from './categories/dirtyFlagPlugin';

import filter from './filter';

import nav from './nav';

import products from './products';
import { dirtyProductFlag } from './products/dirtyFlagPlugin';

import restrictions from './restrictions';

import tags from './tags';

import auditLog from './audit-log';
import { createStore } from 'vuex';

const store = createStore({
    modules: {
        auth,
        categories,
        filter,
        nav,
        products,
        restrictions,
        tags,
        auditLog,
    },
    strict: process.env.NODE_ENV === 'development',
    plugins: [
        dirtyCategoryFlag,
        dirtyProductFlag,
    ],
});


export default store;
