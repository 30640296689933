import { canSaveCurrent } from '../shared/canSaveCurrent';
import { isEmbeddedSection } from './embeddedSection';
import { isBlockValid } from '../../src/shared/block/isBlockValid';

export const getters = {
    canSaveCurrent,

    canCompareRevisions(state, getters) {
        return getters.previousRevision !== null;
    },

    canComparePublishedRevisions(state, getters) {
        return getters.previousPublishedRevision !== null;
    },

    /**
     * @param state
     * @return {null|IRevision}
     */
    previousPublishedRevision(state) {
        if (!state.current || state.current.revision === 0) {
            return null;
        }

        if (state.current.revisions === null) {
            console.error('Product doesn\'t contain revisions');
            return null;
        }

        let previousPublished = null;

        for (let revisionIndex = state.current.revisions.length - 1; revisionIndex > 0; revisionIndex--) {
            const revision = state.current.revisions[revisionIndex];
            if (revision.published && revision.revision !== state.current.revision) {
                previousPublished = revision;
                break;
            }
        }

        return previousPublished;
    },

    previousRevision(state) {
        if (!state.current || state.current.revision === 0) {
            return null;
        }

        if (state.current.revisions === null) {
            console.error('Product doesn\'t contain revisions');
            return null;
        }

        let previousRevision = null;

        for (let i = state.current.revision - 1; i > 0; i--) {
            const previous = state.current.revisions.find(rev => rev.revision == i);
            if (previous) {
                previousRevision = previous;
                break;
            }
        }

        return previousRevision;
    },

    /**
     *
     * @param {IProductState} state
     * @return {Array}
     */
    embeddableBlocks(state) {
        if (!state.current) {
            return [];
        }

        const embeddableSection = state.current.sections.find(isEmbeddedSection);
        if (!embeddableSection) {
            return [];
        }

        return embeddableSection.blocks.filter(isBlockValid);
    },
};
