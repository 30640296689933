export {
    setError,
    setFetching,

    setList,
    updateList,

    setCurrent,
    updateCurrent,
} from '../shared/commonTypes';

export const createEmpty = 'createEmpty';
export const createCategoryFrom = 'createCategoryFrom';

export const setFullList = 'setFullList';
export const deleteProduct = 'deleteProduct';
