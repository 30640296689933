<template>
    <article class="columnEditorText">
        <quill-editor
            class="quill-editor"
            content-type="html"
            :options="editorOptions"
            :content="content"
            @update:content="$emit('input',$event)"
        />
    </article>
</template>

<script>
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { QuillEditor } from '@vueup/vue-quill';

import { columnEditorProps } from '../columnEditorProps';

export default {
    name: 'ColumnEditorText',

    components: {
        QuillEditor,
    },

    mixins: [
        columnEditorProps(),
    ],
    emits: ['input'],
    data() {
        return {
            editorOptions: {
                theme: 'snow',
                modules: {
                    toolbar: {
                        container: [
                            [{ 'header': [1, 2, false] }],
                            ['bold', 'italic', 'strike', 'underline', 'link'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            [{ 'script': 'sub' }, { 'script': 'super' }],
                            ['clean'],
                            [
                                'lte',
                                'gte',
                                'plusminus',
                            ],
                        ],
                        handlers: {
                            gte: function() {
                                const range = this.quill.getSelection();
                                if (range) {
                                    this.quill.insertText(range.index, '≥');
                                }
                            },
                            lte: function() {
                                const range = this.quill.getSelection();
                                if (range) {
                                    this.quill.insertText(range.index, '≤');
                                }
                            },
                            plusminus: function() {
                                const range = this.quill.getSelection();
                                if (range) {
                                    this.quill.insertText(range.index, '±');
                                }
                            },
                        },
                    },
                },
                formats: ['header', 'bold', 'italic', 'strike', 'underline', 'link', 'list', 'script', 'gte', 'lte', 'plusminus'],
                readOnly: this.readOnly,
            },
        };
    },
};
</script>

<style scoped>
.columnEditorText {
    width: 100%;
    height: 100%;
}
.quill-editor {
    width: 100%;
    height: calc(100% - 20px);
}
</style>

<style lang="stylus">
.ql-container.ql-snow {
    border: 0 none;
}

.ql-toolbar.ql-snow {
    border-width: 0 0 1px 0;
}

.ql-gte:after {
    content: "≥";
}

.ql-lte:after {
    content: "≤";
}
.ql-plusminus:after {
    content: "±";
}

.ql-editor {
    font-family: Roboto, sans-serif;

    h1 {
        font-weight: lighter;
        font-size: 24px;
        color: #666666;
    }

    h2 {
        font-weight: normal;
        font-size: 16px;
        font-style: italic;
        color: #000000;
    }

    p, li {
        font-weight: lighter;
        font-size: 16px;
        color: #505050;
    }
}
</style>
