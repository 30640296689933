import { api } from '../api';

export async function uploadImage(file) {
    const response = await api({
        method: 'post',
        headers: {
            'Content-Type': file.type,
        },
        url: `/media/upload?filename=${encodeURIComponent(file.name)}`,
        data: file,
    });

    return response.data.absoluteUri;
}
