export const columnAttrs = {
    methods: {
        /**
         * @param {IBlock} block
         * @return {object}
         */
        columnAttrs(block) {
            const columnSpan = Math.round((12 / block.columns.length));
            return {
                md: columnSpan,
            };
        },
    },
};
