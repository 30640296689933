import { setError, setFetching } from './commonTypes';

export async function wrapRequest({ commit }, handler, ...args) {
    commit(setFetching, true);

    try {
        const result = await handler(...args);
        commit(setError, null);
        return result;
    } catch (e) {
        commit(setError, e);
    } finally {
        commit(setFetching, false);
    }
}
