import { sectionFactory } from '../shared/sections/sectionFactory';

export const embeddedSectionTitle = '__produktspesifikt innhold';

export function isEmbeddedSection(section) {
    return section.title === embeddedSectionTitle;
}

export function createEmbeddedSection() {
    return sectionFactory({title: embeddedSectionTitle});
}
