import { setDirty } from './dirtyTypes';
import { setCurrent } from '../commonTypes';

export const dirtyMutations = {
    [setDirty](state, isDirty) {
        return state.isDirty = isDirty;
    },

    [setCurrent](state, current) {
        state.current = current;
        state.isDirty = false;
    },
};
