export function columnEditorProps(contentType = String) {
    return {
        props: {
            content: {
                type: contentType,
                required: true,
            },

            readOnly: {
                type: Boolean,
                default: false,
            },

            uuid: {
                type: String,
                required: true,
            },

            columnIndex: {
                type: Number,
                required: true,
            },
        },
    };
}
