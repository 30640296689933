<template>
    <article class="gridEditorCategory">
        <v-row no-gutters class="flex-wrap editorButtons my-3" align="center" justify="center">
            <v-btn :disabled="buttonsDisabled" size="small" @click="deleteRow">
                <v-icon size="small">delete</v-icon>
                {{ $t('deleteRow') }}
            </v-btn>
            <v-btn :disabled="buttonsDisabled" size="small" @click="insertRowBefore">
                <v-icon size="small">keyboard_arrow_up</v-icon>
                {{ $t('insertRowBefore') }}
            </v-btn>
            <v-btn :disabled="buttonsDisabled" size="small" @click="insertRowAfter">
                <v-icon size="small">keyboard_arrow_down</v-icon>
                {{ $t('insertRowAfter') }}
            </v-btn>

            <v-btn :disabled="colSpanDisabled" size="small" @click="onColSpanClick">
                <v-icon size="small">vertical_split</v-icon>
                {{ $t('mergeCellRight') }}
            </v-btn>
            <v-btn :disabled="rowSpanDisabled" size="small" @click="onRowSpan">
                <v-icon size="small">horizontal_split</v-icon>
                {{ $t('mergeCellDown') }}
            </v-btn>
            <v-btn :disabled="splitDisabled" size="small" @click="onSplit">
                <v-icon size="small">view_agenda</v-icon>
                {{ $t('splitCells') }}
            </v-btn>

            <v-btn :disabled="formatDisabled" size="small" @click="markCellItalic">
                <v-icon>format_italic</v-icon>
            </v-btn>
            <v-btn :disabled="formatDisabled" size="small" @click="markCellBold">
                <v-icon>format_bold</v-icon>
            </v-btn>
            <v-btn :disabled="formatDisabled" size="small" @click="markTextSubscript">xₙ</v-btn>
            <v-btn :disabled="formatDisabled" size="small" @click="markTextSuperscript">x²</v-btn>

            <!-- TODO: verify if these props were needed  <v-menu :disabled="formatDisabled" offset-y open-on-hover> -->
            <v-menu :disabled="formatDisabled" open-on-hover>
                <template #activator="{ props }">
                    <v-btn v-bind="props" :disabled="formatDisabled" size="small">{{ $t('glyphs') }}</v-btn>
                </template>
                <v-list>
                    <v-list-item @click="insertChar('≤')">
                        <v-list-item-title>≤</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="insertChar('≥')">
                        <v-list-item-title>≥</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="insertChar('±')">
                        <v-list-item-title>±</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

        </v-row>

        <grid-wrapper
            :content="content"
            class="grid"
        >
            <template #caption="{caption}">
                <input :value="caption" :placeholder="$t('tableCaption')" type="text" @input="onInputCaption">
            </template>
            <template #headerCell="{header, index}">
                <textarea
                    :ref="_getHeaderRef(index)"
                    :value="extractCellText(header)"
                    :placeholder="$t('newColumn')"
                    @input="onInputHeader($event, index)"
                    @focus="onHeaderFocus(index)"
                    @blur="onCellBlur"
                />
            </template>
            <template #bodyCell="{value, col, row}">
                <textarea
                    :ref="_getCellRef(row, col)"
                    :value="extractCellText(value)"
                    @input="onInputCell($event, row, col)"
                    @focus="onCellFocus(row, col)"
                    @blur="onCellBlur"
                />
            </template>
        </grid-wrapper>
    </article>
</template>

<script>
import GridWrapper from './GridWrapper';
import { createGridRow } from './createGridRow';
import {
    applyColSpan,
    applyRowSpan,
    applySplit,
    extractCellText, insertCharIntoCell, insertSub, insertSup, setCellText, toggleBold,
    toggleItalic,
} from './cellOperations';
import { splice2d } from './splice2d';

export default {
    name: 'GridEditorCategory',

    components: {
        GridWrapper,
    },

    props: {
        content: {
            type: Object,
            required: true,
        },
    },
    emits: ['input'],

    data() {
        return {
            currentRow: null,
            currentCol: null,

            headerCol: null,

            blurTimeout: null,
        };
    },

    computed: {
        isCellSelected() {
            return this.currentRow !== null && this.currentCol !== null;
        },

        isHeaderSelected() {
            return this.headerCol !== null;
        },

        selectedCell() {
            if (this.isCellSelected && this.content.grid[this.currentRow]) {
                return this.content.grid[this.currentRow][this.currentCol];
            } else {
                return null;
            }
        },

        selectedHeader() {
            if (this.isHeaderSelected) {
                return this.content.headers[this.headerCol];
            } else {
                return null;
            }
        },

        buttonsDisabled() {
            return !this.isCellSelected;
        },

        canHeaderColSpan() {
            return this.selectedHeader !== null;
        },

        canColSpan() {
            return (this.isCellSelected && this.currentCol !== this.content.cols && this.selectedCell.rowSpan === undefined) || this.canHeaderColSpan;
        },

        canRowSpan() {
            return this.isCellSelected && this.currentRow !== this.content.rows && this.selectedCell.colSpan === undefined;
        },

        colSpanDisabled() {
            return !this.canColSpan;
        },

        rowSpanDisabled() {
            return !this.canRowSpan;
        },

        formatDisabled() {
            return this.selectedCell === null && this.selectedHeader === null;
        },

        splitDisabled() {
            if (this.isCellSelected) {
                return this.selectedCell.text === undefined;
            } else if (this.isHeaderSelected) {
                return this.selectedHeader.text === undefined;
            } else {
                return true;
            }
        },
    },

    methods: {
        onInputCaption(event) {
            this.$emit('input', {
                ...this.content,
                caption: event.currentTarget.value,
            });
        },

        onInputHeader(event, index) {
            const value = event.currentTarget.value;
            const content = this.cloneContent();
            content.headers[index] = setCellText(this.content.headers[index], value);

            this.$emit('input', content);
        },

        onInputCell(event, row, col) {
            const value = event.currentTarget.value;
            const newGrid = splice2d(this.content.grid, row, col, setCellText(this.content.grid[row][col], value));

            this.$emit('input', {
                ...this.content,
                grid: newGrid,
            });
        },

        onColSpanClick() {
            if (this.isHeaderSelected) {
                this.onColSpanHeader(this.headerCol);
            } else if (this.isCellSelected) {
                this.onColSpanCell(this.currentRow, this.currentCol);
            }
        },

        _updateCell(row, col, updater) {
            const content = this.cloneContent();
            content.grid[row][col] = updater(content.grid[row][col]);

            this.$emit('input', content);
        },

        _updateHeader(colIndex, updater) {
            const content = this.cloneContent();

            content.headers[colIndex] = updater(content.headers[colIndex]);

            this.$emit('input', content);
        },

        onColSpanCell(row, col) {
            this._updateCell(row, col, applyColSpan);
        },

        onColSpanHeader(col) {
            this._updateHeader(col, applyColSpan);
        },

        onRowSpan() {
            this._updateCell(this.currentRow, this.currentCol, applyRowSpan);
        },

        onSplit() {
            if (this.isCellSelected) {
                this._updateCell(this.currentRow, this.currentCol, applySplit);
            } else if (this.isHeaderSelected) {
                this._updateHeader(this.headerCol, applySplit);
            }
        },

        onCellFocus(rowIndex, colIndex) {
            clearTimeout(this.blurTimeout);

            this.currentRow = rowIndex;
            this.currentCol = colIndex;
        },

        onHeaderFocus(colIndex) {
            clearTimeout(this.blurTimeout);

            this.headerCol = colIndex;
        },

        onCellBlur() {
            this.blurTimeout = setTimeout(() => {
                this.currentRow = null;
                this.currentCol = null;
                this.headerCol = null;
            }, 200);
        },

        deleteRow() {
            const grid = [
                ...this.content.grid.slice(0, this.currentRow),
                ...this.content.grid.slice(this.currentRow + 1),
            ];

            this.$emit('input', {
                ...this.content,
                grid,
                rows: this.content.rows - 1,
            });
        },

        insertRowBefore() {
            const grid = [
                ...this.content.grid.slice(0, this.currentRow - 1),
                createGridRow(this.content.cols),
                ...this.content.grid.slice(this.currentRow - 1),
            ];

            this.$emit('input', {
                ...this.content,
                grid,
                rows: this.content.rows + 1,
            });
        },
        insertRowAfter() {
            const grid = [
                ...this.content.grid.slice(0, this.currentRow + 1),
                createGridRow(this.content.cols),
                ...this.content.grid.slice(this.currentRow + 1),
            ];

            this.$emit('input', {
                ...this.content,
                grid,
                rows: this.content.rows + 1,
            });
        },

        markCellItalic() {
            if (this.selectedCell !== null) {
                this._updateCell(this.currentRow, this.currentCol, toggleItalic);
            } else {
                this._updateHeader(this.headerCol, toggleItalic);
            }
        },

        markCellBold() {
            if (this.selectedCell !== null) {
                this._updateCell(this.currentRow, this.currentCol, toggleBold);
            } else {
                this._updateHeader(this.headerCol, toggleBold);
            }
        },

        markTextSuperscript() {
            let textarea;
            if (this.selectedCell !== null) {
                textarea = this.$refs[this._getCellRef(this.currentRow, this.currentCol)];
                this._updateCell(this.currentRow, this.currentCol, (cell) => {
                    return insertSup(cell, textarea.selectionStart, textarea.selectionEnd);
                });
            } else {
                textarea = this.$refs[this._getHeaderRef(this.headerCol)];
                this._updateHeader(this.headerCol, (cell) => {
                    return insertSup(cell, textarea.selectionStart, textarea.selectionEnd);
                });
            }
        },

        markTextSubscript() {
            let textarea;
            if (this.selectedCell !== null) {
                textarea = this.$refs[this._getCellRef(this.currentRow, this.currentCol)];
                this._updateCell(this.currentRow, this.currentCol, (cell) => {
                    return insertSub(cell, textarea.selectionStart, textarea.selectionEnd);
                });
            } else {
                textarea = this.$refs[this._getHeaderRef(this.headerCol)];
                this._updateHeader(this.headerCol, (cell) => {
                    return insertSub(cell, textarea.selectionStart, textarea.selectionEnd);
                });
            }
        },

        insertChar(char) {
            const {textarea, isHeader} = this._getTextArea();
            if (isHeader) {
                this._updateHeader(this.headerCol, (cell) => {
                    return insertCharIntoCell(cell, char, textarea.selectionStart, textarea.selectionEnd);
                });
            } else {
                this._updateCell(this.currentRow, this.currentCol, (cell) => {
                    return insertCharIntoCell(cell, char, textarea.selectionStart, textarea.selectionEnd);
                });
            }
        },

        _getTextArea() {
            let textarea;
            let isHeader = false;

            if (this.selectedCell !== null) {
                textarea = this.$refs[this._getCellRef(this.currentRow, this.currentCol)];
            } else {
                textarea = this.$refs[this._getHeaderRef(this.headerCol)];
                isHeader = true;
            }

            return {
                textarea,
                isHeader,
            };
        },

        _getCellRef(row, col) {
            return `cell-${col}-${row}`;
        },

        _getHeaderRef(col) {
            return `header-${col}`;
        },

        extractCellText,

        cloneContent() {
            return JSON.parse(JSON.stringify(this.content));
        }
    },
};
</script>

<style scoped lang="stylus">
$cellPadding = 10px;

.editorButtons {
    gap: 12px;
}

.grid {
    margin: $cellPadding;

    textarea {
        outline: 1px solid transparent;
        transition: all 150ms linear;
        width: 100%;
        height: 100%;
        resize: none;

        &::placeholder {
            font-style: italic;
        }
    }

    td:hover,
    th:hover {
        textarea,
        input {
            outline: 1px solid silver;
            transition: all 150ms linear;
        }
    }

    caption {
        input {
            text-align: center;
            width: 100%;
            max-width: 200px;
            color: inherit;

            &:hover,
            &:focus {
                background-color: rgba(255, 255, 255, 0.2);
            }

            &::placeholder {
                color: rgba(200, 200, 200, 0.7);
            }
        }
    }
}
</style>
