export {
    setError,
    setFetching,

    setList,
    updateList,
} from '../shared/commonTypes';

export const addToList = 'addToList';
export const deleteFromList = 'deleteFromList';
