<template>
    <v-row align-center tag="article">
        <v-col cols="12" md="2">
            <revision-selector
                v-if="category.revisions !== null"
                :revisions="category.revisions"
                :current="category.revision"
                @revertTo="$emit('revertTo', $event)"
            />
        </v-col>
        <v-spacer />
        <div v-if="!category.deletedAt">
            <v-btn :to="{name: 'addProduct', params: {id: category.id}}" variant="outlined" class="addProduct">
                <template #prepend>
                    <v-icon>note_add</v-icon>
                </template>
                {{ $t('addProduct') }}
            </v-btn>
            <v-btn :disabled="!canSave" color="primary" @click="$emit('save')">
                <template #prepend>
                    <v-icon>save</v-icon>
                </template>
                {{ $t('save') }}
            </v-btn>
        </div>
    </v-row>
</template>

<script>
import RevisionSelector from '../../shared/RevisionSelector';

export default {
    name: 'CategoryItemManagement',

    components: {
        RevisionSelector,
    },

    props: {
        category: {
            type: Object,
            required: true,
        },

        canSave: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['save', 'revertTo'],
};
</script>

<style scoped>
.addProduct {
    margin-right: 10px;
}
</style>
