export {
    setError,
    setFetching,

    setList,
    updateList,

    setCurrent,
    updateCurrent,
} from '../shared/commonTypes';

export const markBlockToggled = 'markBlockToggled';
export const setGridAppendix = 'setGridAppendix';
export const markApproved = 'markApproved';
export const markPublished = 'markPublished';
export const embedBlock = 'embedBlock';
export const unembedBlock = 'unembedBlock';
