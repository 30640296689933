import { Resource } from '../shared/Resource.js';

export class AuditLogResource extends Resource {
    generateDiff(recordId) {
        return this.api({
            method: 'post',
            url: this.getUrl(),
            data: {id: recordId},
        });
    }

    getAuditLog(filter = {}) {
        return this.api({
            method: 'get',
            url: this.getUrl(),
            params: filter,
        });
    }
}
