<template>
    <v-row justify="end">
        <v-col cols="auto">
            <v-menu v-show="isAuthenticated">
                <template #activator="{ props }">
                    <v-row no-gutters v-bind="props" class="pointer">
                        <v-col>
                            <v-avatar size="48" class="mr-2 avatar">
                                <span class="text-white text-h5">{{ initials }}</span>
                            </v-avatar>
                        </v-col>
                        <v-col class="text-caption">
                            <p class="namePart">{{ fullName }}</p>
                            <p class="namePart">{{ email }}</p>
                        </v-col>
                    </v-row>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title>
                            <v-icon>{{ adminIcon }}</v-icon>
                            <span>{{ $t(adminStatus) }}</span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-btn block class="bg-primary" variant="tonal" v-if="isAdmin" @click="onPretendUserClick">{{ $t(pretendUserStatus) }}</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn block variant="outlined" v-if="isAdmin" :to="{name: 'restrictions'}">{{ $t('restrictionsManagement') }}</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn block color="warning" @click="onLogOutClick">{{ $t('logOut') }}</v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { adalWrapper } from '../store/adalWrapper.js';

export default {
    name: 'UserAvatar',

    computed: {
        ...mapGetters('auth', ['viewAsAdmin', 'email', 'name', 'fullName', 'initials']),
        ...mapState('auth', ['isAuthenticated', 'isAdmin']),

        adminIcon() {
            return this.isAdmin ? 'verified_user' : 'supervised_user_circle';
        },
        adminStatus() {
            return this.isAdmin ? 'userAdmin' : 'userReadOnly';
        },

        pretendUserStatus() {
            return this.viewAsAdmin ? 'viewAsUser' : 'viewAsAdmin';
        },
    },

    methods: {
        ...mapMutations('auth', ['togglePretendUser']),

        onLogOutClick() {
            adalWrapper.logout();
        },

        onPretendUserClick() {
            this.togglePretendUser();
        },
    },
};
</script>

<style scoped>
.avatar {
    background-color: rgba(0, 0, 0, 0.25);
}
.dropdownContent {
    background-color: white;
}

.namePart {
    display: flex;
    align-items: center;
    font-size: 110%;
    color: white;
}
</style>
