import { api } from '../api';
import { ProductResource } from './ProductResource';
import { revisionedActionFactory } from '../shared/revisioned/revisionedActions';
import { commonActionsFactory } from '../shared/commonActions';
import { categoryResource } from '../categories/actions';
import { wrapRequest } from '../shared/wrapRequest';
import { setCurrent, setList, updateCurrent } from '../shared/commonTypes';
import { markApproved, markPublished } from './types';
import { deleteProduct } from '../categories/types';
import { createEmbeddedSection, isEmbeddedSection } from './embeddedSection';

const productResource = new ProductResource(api, 'Products', categoryResource);

/**
 * @param content
 * @param meta
 * @return {IProduct}
 */
function hydrate({content, ...meta}) {
    const clientProduct = {
        ...meta,
        sections: content ? content.sections || [] : [],
        overrides: content && content.overrides || {},
        gridAppendices: content ? content.gridAppendices || {} : {},
        embeddedBlocks: content ? content.embeddedBlocks || {} : {},
    };

    if (clientProduct.sections.find(isEmbeddedSection) === undefined) {
        clientProduct.sections.push(createEmbeddedSection());
    }

    clientProduct.modifiedAt = new Date(clientProduct.modifiedAt);
    clientProduct.createdAt = new Date(clientProduct.createdAt);

    return clientProduct;
}

function dehydrate({sections, overrides, gridAppendices, embeddedBlocks, ...meta}) {
    return {
        ...meta,
        content: {
            sections,
            overrides: overrides || {},
            gridAppendices: gridAppendices || {},
            embeddedBlocks: embeddedBlocks || {},
        },
    };
}

async function approve(context, { id, revision, productGroupRevision }) {
    await wrapRequest(context, async () => {
        return await productResource.approve(id, revision, productGroupRevision);
    });

    context.commit(markApproved, { id, revision });
}

async function publish(context, { id, revision }) {
    const response = await wrapRequest(context, async () => {
        await productResource.publish(id, revision);
        return await productResource.read(id);
    });

    context.commit(markPublished, response.data);
}

const commonActions = commonActionsFactory(productResource, hydrate, dehydrate);

export const actions = {
    ...commonActions,

    ...revisionedActionFactory(productResource, hydrate),

    async list(context, parentId) {
        const response = await wrapRequest(context, async () => {
            return await productResource.list({}, parentId);
        });

        context.commit(setList, response.data.map(hydrate));
    },

    async create(context, {product, parentId}) {
        const response = await wrapRequest(context, async () => {
            return await productResource.create(dehydrate(product), parentId);
        });

        context.commit(updateCurrent, hydrate({id: response.data, ...product}));

        return response.data;
    },

    approve,

    async approveCurrent(context) {
        const {id, revision} = context.state.current;

        await approve(context, {id, revision, productGroupRevision: context.rootState.categories.current.revision});
    },

    publish,

    async publishCurrent(context) {
        const { id, revision } = context.state.current;

        await publish(context, {id, revision});
    },

    async delete(context, {product, categoryId}) {
        await commonActions.delete(context, product);
        context.commit(`categories/${deleteProduct}`, {productId: product.id, categoryId}, {root: true});
    },

    async bulkApprove(context, /** IProduct[] */ products) {
        await wrapRequest(context, async () => {
            return await productResource.bulkApprove(
                products.map((product) => {
                    return {
                        id: product.id,
                        revision: product.revision,
                        productGroupId: product.productGroupId,
                    };
                })
            );
        });
    },

    async bulkPublish(context, /** IProduct[] */ products) {
        await wrapRequest(context, async () => {
            return await productResource.bulkPublish(
                products.map((product) => {
                    return {
                        id: product.id,
                        revision: product.revision,
                    };
                })
            );
        });
    },

    async readPublished(context, id) {
        if (id === undefined || id === null) {
            throw new Error('CRUD Read: invalid id');
        }
        const response = await wrapRequest(context, async () => {
            return await productResource.readPublished(id);
        });

        const item = hydrate(response.data);

        context.commit(setCurrent, item);

        return item;
    },
};
