function setPretendUser(state, pretendUser) {
    if (pretendUser) {
        localStorage.setItem('isUser', 'true');
    } else {
        localStorage.removeItem('isUser');
    }

    state.pretendUser = pretendUser;
}

export const mutations = {
    login(state, profile) {
        state.profile = profile;
        state.isAuthenticated = true;
    },

    setIsAdmin(state, isAdmin) {
        state.isAdmin = isAdmin;
    },

    setIsReviewer(state, isReviewer) {
        state.isReviewer = isReviewer;
    },

    logout(state) {
        state.profile = null;
        state.isAuthenticated = false;
    },

    setAuthenticated(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated;
    },

    setPretendUser,

    togglePretendUser(state) {
        setPretendUser(state, !state.pretendUser);
    },
};
