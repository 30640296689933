<template>
    <v-dialog :model-value="true" persistent max-width="50%">
        <v-card>
            <v-card-title class="pa-4 text-h5 bg-primary text-white">{{ title }}</v-card-title>
            <v-card-text class="px-4">
                <slot />
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-4">
                <v-spacer />
                <v-btn @click="$emit('cancel')" variant="tonal">{{ $t('cancel') }}</v-btn>
                <v-btn :disabled="confirmDisabled" class="bg-primary" @click="$emit('confirm')">
                    {{ confirmLabel }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'CreateItemDlg',

    props: {
        title: {
            type: String,
            required: true,
        },
        confirmDisabled: {
            type: Boolean,
            required: true,
        },
        confirmLabel: {
            type: String,
            required: true,
        },
    },
    emits: ['confirm', 'cancel'],
};
</script>

<style scoped>

</style>
