import { commonMutations } from '../shared/commonMutations';
import { sectionMutations } from '../shared/sections/sectionMutations';
import { blockMutations } from '../shared/blocks/blockMutations';
import { columnMutations } from '../shared/columns/columnMutations';
import { dirtyMutations } from '../shared/dirty/dirtyMutations';
import { revisionedMutations } from '../shared/revisioned/revisionedMutations';

import {
    markApproved,
    markPublished,
    setGridAppendix,
    markBlockToggled,
    embedBlock,
    unembedBlock,
} from './types';

/**
 * @param {IProduct} product
 * @param {TUuid} id
 * @param {number} revision
 */
function tryMarkProductApproved(product, id, revision) {
    if (product.id === id && product.revision === revision) {
        product.approved = true;
    }
}

export const mutations = {
    ...commonMutations,
    ...sectionMutations,
    ...blockMutations,
    ...columnMutations,
    ...dirtyMutations,
    ...revisionedMutations,

    [markBlockToggled](state, { blockId, value }) {
        const overrides = state.current.overrides;

        overrides[blockId] = value;
    },

    [setGridAppendix](state, {uuid, columnIndex, value, row, col}) {
        const gridAppendices = state.current.gridAppendices;

        if (!(uuid in gridAppendices)) {
            gridAppendices[uuid] = {};
        }

        if (!(columnIndex in gridAppendices[uuid])) {
            gridAppendices[uuid][columnIndex] = {};
        }

        if (!(row in gridAppendices[uuid][columnIndex])) {
            gridAppendices[uuid][columnIndex][row] = {};
        }

        gridAppendices[uuid][columnIndex][row][col] = value;
    },

    [markApproved](state, {id, revision}) {
        if (state.current) {
            tryMarkProductApproved(state.current, id, revision);
        }

        state.list.map((product) => tryMarkProductApproved(product, id, revision));
    },

    [markPublished](state, item) {
        state.current.status = item.status;
        state.current.revisions = item.revisions;
    },

    [embedBlock](state, {sectionId, blockId, blockEmbedId}) {
        const embeddedBlocks = state.current.embeddedBlocks;
        if (!embeddedBlocks[sectionId]) {
            embeddedBlocks[sectionId] = {};
        }

        if (!embeddedBlocks[sectionId][blockId]) {
            embeddedBlocks[sectionId][blockId] = [];
        }

        embeddedBlocks[sectionId][blockId].push(blockEmbedId);
    },

    [unembedBlock](state, {sectionId, blockId, blockEmbedId}) {
        const embeddedBlocks = state.current.embeddedBlocks;

        if (embeddedBlocks[sectionId] && embeddedBlocks[sectionId][blockId]) {
            const index = embeddedBlocks[sectionId][blockId].findIndex(id => blockEmbedId === id);
            if (index > -1) {
                embeddedBlocks[sectionId][blockId].splice(index, 1);
            }
        }
    },
};
