export const datePickerLocale = {
    computed: {
        dtLocale() {
            if (this.$i18n.locale === 'en') {
                return 'en';
            } else {
                return 'nb'; // bokmål language code
            }
        },
    },
};
