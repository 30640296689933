/**
 * @param {Partial<ISection>} overrides
 * @return {ISection}
 */
export function categoryFactory(overrides = {}) {
    return {
        title: '',
        sections: [],
        revisions: [],
        revision: 0,
        ...overrides,
    };
}
