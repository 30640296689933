export const getters = {
    breadcrumbs(state) {
        return (isAdmin) => {
            const result = [];

            if (state.category) {
                const link = isAdmin ? { name: 'categories-id', params: {id: state.category.id}} : { name: 'index', params: {categoryId: state.category.id}};
                result.push({
                    link,
                    title: state.category.title,
                    adminOnly: !!state.product, // only if there's product
                });
            }

            if (state.product) {
                result.push({
                    link: {
                        name: 'products-id', params: { id: state.product.id },
                    },
                    title: state.product.title,
                });
            }

            return result;
        };
    },
};
