import {
    setCurrent,
    setError,
    setFetching,
    updateCurrent,
    setList,
    updateList,
    deleteFromList,
} from './commonTypes';

export const commonMutations = {
    [setError](state, error) {
        if (error) {
            console.error(error);
        }
        if (error && error.message) {
            state.error = error.message;
        } else {
            state.error = error;
        }
    },

    [setFetching](state, value) {
        state.isFetching = !!value;
    },

    [setList](state, list) {
        state.list = list;
    },

    [setCurrent](state, current) {
        state.current = current;
    },

    [updateCurrent](state, patch) {
        if (state.current !== null) {
            Object.assign(state.current, patch);
        }
    },

    [updateList](state, patchedItem) {
        if (state.list.length === 0) {
            return;
        }

        const toPatch = state.list.find((item) => item.id === patchedItem.id);
        if (toPatch) {
            Object.assign(toPatch, patchedItem);
        }
    },

    [deleteFromList](state, id) {
        state.list = state.list.filter(item => item.id !== id);
    },
};
