export function cellStyleClass(cell) {
    if (typeof cell === 'string') {
        return {};
    } else {
        return {
            bold: cell.bold,
            italic: cell.italic,
        };
    }
}
