import { Resource } from '../shared/Resource.js';

export class RestrictionsResource extends Resource {
    save(restriction) {
        return this.api({
            method: 'patch',
            url: this.getUrl(),
            data: restriction,
        });
    }
}
