import nanoid from 'nanoid';

import { predefinedSections } from './predefinedSections.js';

/**
 * @return {ISection}
 */
export function sectionFactory(overrides = {}) {
    return {
        id: nanoid(),
        title: '',
        blocks: [],

        ...overrides,
    };
}

/**
 * @return {ISection[]}
 */
export function defaultSectionsFactory() {
    return predefinedSections.map((title) => sectionFactory({title}));
}
