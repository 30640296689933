import {
    appendBlock,
    copyBlock,
    createBlock,
    deleteBlock,
    markBlockGeneral,
    reorderBlocks,
    stopEditBlock,
} from './blockTypes';
import { blockFactory } from './blockFactory';
import { reorderArray } from '../reorderArray';

export const blockMutations = {
    [appendBlock](state, { sectionIndex, columnsCount }) {
        /** @type ISection */
        const section = state.current.sections[sectionIndex];

        if (section) {
            const block = blockFactory(columnsCount);
            section.blocks.push(block);
        }
    },

    [createBlock](state, { sectionIndex, blockIndex }) {
        /** @type ISection */
        const section = state.current.sections[sectionIndex];

        if (section) {
            const newBlock = blockFactory(null);
            section.blocks.splice(blockIndex + 1, 0, newBlock);
        }
    },

    [reorderBlocks](state, { sectionIndex, oldIndex, newIndex }) {
        /** @type ISection */
        const section = state.current.sections[sectionIndex];

        if (section) {
            reorderArray(section.blocks, { oldIndex, newIndex });
        }
    },

    [copyBlock](state, { sectionIndex, blockIndex }) {
        const section = state.current.sections[sectionIndex];

        if (section) {
            const block = section.blocks[blockIndex];
            const clone = JSON.parse(JSON.stringify(block));
            section.blocks.splice(blockIndex, 0, clone);
        }
    },

    [stopEditBlock](state, { sectionIndex, blockIndex }) {
        /** @type ISection */
        const section = state.current.sections[sectionIndex];

        if (section) {
            const block = section.blocks[blockIndex];
            block.edited = false;
        }
    },

    [deleteBlock](state, { sectionIndex, blockIndex }) {
        /** @type ISection */
        const section = state.current.sections[sectionIndex];

        if (section) {
            section.blocks.splice(blockIndex, 1);
        }
    },

    [markBlockGeneral](state, { sectionIndex, blockIndex, value }) {
        /** @type ISection */
        const section = state.current.sections[sectionIndex];

        if (section) {
            const block = section.blocks[blockIndex];
            if (block) {
                block.isGeneralInfo = value;
            }
        }
    },
};
