import { wrapRequest } from '../wrapRequest';
import { setCurrent } from '../commonTypes';
import { setPreviousRevision, setPreviousPublishedRevision } from './revisionedTypes';

export function revisionedActionFactory(resource, hydrate) {
    async function getRevision(context, { id, revision }) {
        const response = await wrapRequest(context, async () => {
            return await resource.readRevision(id, revision);
        });

        return hydrate(response.data);
    }

    return {
        async readRevision(context, { id, revision }) {
            const item = await getRevision(context, {id, revision});
            context.commit(setCurrent, item);
            return item;
        },

        async readPreviousRevision(context) {
            const item = await getRevision(context,
                {
                    id: context.state.current.id,
                    revision: context.getters.previousRevision?.revision,
                }
            );
            context.commit(setPreviousRevision, item);
            return item;
        },

        async readPreviousPublishedRevisionForCurrent(context) {
            const item = await getRevision(
                context,
                {
                    id: context.state.current.id,
                    revision: context.getters.previousPublishedRevision?.revision,
                }
            );
            context.commit(setPreviousPublishedRevision, item);
            return item;
        },
    };
}
