function firstToUpper(str) {
    return str.substring(0, 1).toLocaleUpperCase() + str.substring(1).toLocaleLowerCase();
}

export const getters = {
    viewAsAdmin(state) {
        if (state.isAdmin) {
            return !state.pretendUser;
        } else {
            return false;
        }
    },

    email(state) {
        const profile = state.profile;
        if (profile !== null) {
            return profile.userName || profile.email;
        } else {
            return '';
        }
    },

    name(state) {
        if (state.isAuthenticated) {
            const profile = state.profile;
            if (profile === null) {
                return [];
            }

            if (profile.name) {
                return profile.name.split(',')
                    .map((chunk) => chunk.trim())
                    .reverse();
            } else {
                const [name] = profile.userName.split('@');
                return name.split('.').map(firstToUpper);
            }

        } else {
            return [];
        }
    },

    fullName(state, getters) {
        return getters.name.join(' ');
    },

    initials(state, getters) {
        if (state.isAuthenticated) {
            if (getters.name.length === 2) {
                return (getters.name[0].substring(0, 1) + getters.name[1].substring(0, 1)).toLocaleUpperCase();
            } else {
                return getters.name[0].substring(0, 2).toLocaleUpperCase();
            }
        } else {
            return '';
        }
    },

};
