<template>
    <article class="sectionEditor pb-2">
        <div v-if="section">
            <block-item
                v-for="(block, blockIndex) of section.blocks"
                :key="section.id + block.id"
                :block="block"
                :movable="section.blocks.length > 1"
                :read-only="section.readOnly || readOnly"
                :overridden="overrides ? overrides[block.id] : true"
                @setColumnType="onSetColumnType($event, blockIndex)"
                @setColumnContent="onColumnInput($event, blockIndex)"
                @setColumns="onSetColumns($event, blockIndex)"
                @add="onCreateBlock(blockIndex)"
                @copy="onCopyBlock(blockIndex)"
                @delete="onDeleteBlock(blockIndex)"
                @markBlockToggled="onMarkBlockToggled($event, block.id)"
                @markBlockGeneral="onMarkBlockGeneral($event, blockIndex)"
                @showEmbedBlock="onShowEmbedBlock(block.id)"
                @unembed="onUnembed(block.id, blockIndex)"
                @moveUp="onMoveUp(blockIndex)"
                @moveDown="onMoveDown(blockIndex)"
            />
        </div>
        <div class="block">
            <column-count-selector
                class="columns chooser"
                @chooseColumns="onChooseColumns"
            />
        </div>

        <confirmation-dlg
            :show="blockIndexToDelete !== -1"
            @cancel="blockIndexToDelete = -1"
            @confirm="onBlockDeleteConfirmed"
        >
            <template #body>
                <span>{{ $t('blockDeleteConfirmationBody') }}</span>
            </template>
        </confirmation-dlg>
    </article>
</template>

<script>
import ColumnCountSelector from '../column/ColumnCountSelector';
import BlockItem from '../block/BlockItem';
import ConfirmationDlg from '../ConfirmationDlg';

export default {
    name: 'SectionEditor',

    components: {
        ConfirmationDlg,
        BlockItem,
        ColumnCountSelector,
    },

    props: {
        section: {
            type: Object,
            default: null,
        },

        readOnly: {
            type: Boolean,
            default: false,
        },

        overrides: {
            type: Object,
            default: null,
        },

        embeddedBlocks: {
            type: Object,
            default: null,
        },
    },
    emits: ['unembed', 'showEmbedBlock', 'markBlockGeneral', 'markBlockToggled', 'appendBlock', 'setColumnContent', 'setColumnType', 'setColumns', 'reorderBlocks', 'deleteBlock', 'copyBlock', 'createBlock'],

    data() {
        return {
            blockIndexToDelete: -1,
        };
    },

    computed: {
        blocks() {
            const sectionBlocks = this.section.blocks;
            if (this.embeddedBlocks === null) {
                return sectionBlocks;
            } else {
                return sectionBlocks.reduce((combined, block, index) => {
                    combined.push(block);

                    if (this.embeddedBlocks[index]) {
                        combined.push(...this.embeddedBlocks[index]);
                    }

                    return combined;
                }, []);
            }
        },
    },

    methods: {
        onCreateBlock(blockIndex) {
            this.$emit('createBlock', {blockIndex});
        },
        onCopyBlock(blockIndex) {
            this.$emit('copyBlock', {blockIndex});
        },
        onDeleteBlock(blockIndex) {
            this.blockIndexToDelete = blockIndex;
        },
        onBlockDeleteConfirmed() {
            this.$emit('deleteBlock', { blockIndex: this.blockIndexToDelete });
            this.$nextTick(() => {
                this.blockIndexToDelete = -1;
            });
        },

        onMoveUp(oldIndex) {
            this.$emit('reorderBlocks', {
                oldIndex: oldIndex,
                newIndex: oldIndex - 1,
            });
        },

        onMoveDown(oldIndex) {
            this.$emit('reorderBlocks', {
                oldIndex: oldIndex,
                newIndex: oldIndex + 1,
            });
        },

        onSetColumns(columnsCount, blockIndex) {
            this.$emit('setColumns', {
                columnsCount,
                blockIndex,
            });
        },

        onSetColumnType(payload, blockIndex) {
            this.$emit('setColumnType', {
                blockIndex,
                ...payload,
            });
        },

        onColumnInput(payload, blockIndex) {
            this.$emit('setColumnContent', {
                blockIndex,
                ...payload,
            });
        },

        onChooseColumns(columnsCount) {
            this.$emit('appendBlock', {columnsCount});
        },

        onMarkBlockToggled(payload, blockId) {
            this.$emit('markBlockToggled', {
                blockId,
                ...payload,
            });
        },

        onMarkBlockGeneral(payload, blockIndex) {
            this.$emit('markBlockGeneral', {
                blockIndex,
                ...payload,
            });
        },

        onShowEmbedBlock(blockId) {
            this.$emit('showEmbedBlock', blockId);
        },

        onUnembed(blockEmbedId, blockIndex) {
            let blockId = null;
            for (let i = blockIndex; i >= 0; i--) {
                if (!this.section.blocks[i].embedded) { // find previous non-embedded block
                    blockId = this.section.blocks[i].id;
                    break;
                }
            }

            this.$emit('unembed', {
                blockEmbedId,
                blockId,
                sectionId: this.section.id,
            });
        },
    },
};
</script>

<style scoped lang="stylus">
@require '../block/block.styl';
@require '../readOnly.styl';

.sectionEditor {
    position: relative;
    top: -2 * $tabBorderWidth;
    border-top: #2e73b6 $tabBorderWidth solid;
    z-index: 0;
}
</style>
