import { wrapRequest } from './wrapRequest';
import { setCurrent, setList, updateCurrent } from './commonTypes';
import { deleteFromList } from '../tags/types';

function pass(arg) {
    return arg;
}

/**
 * @param {Resource} resource
 * @param {Function} hydrate
 * @param {Function} dehydrate
 * @return {Object}
 */
export function commonActionsFactory(resource, hydrate = pass, dehydrate = pass) {
    return {
        async list(context) {
            // todo: prevent from duplicating requests
            const response = await wrapRequest(context, async () => {
                return await resource.list();
            });

            if (response && response.data) {
                context.commit(setList, response.data.map(hydrate));
            }
        },

        async read(context, id) {
            if (id === undefined || id === null) {
                throw new Error('CRUD Read: invalid id');
            }
            const response = await wrapRequest(context, async () => {
                return await resource.read(id);
            });

            const item = hydrate(response.data);

            context.commit(setCurrent, item);

            return item;
        },

        async patch(context, patchedItem) {
            const response = await wrapRequest(context, async () => {
                return await resource.patch(dehydrate(patchedItem));
            });

            context.commit(updateCurrent, hydrate(response.data));
        },

        async update(context, savedItem) {
            const response = await wrapRequest(context, async () => {
                return await resource.update(dehydrate(savedItem));
            });

            context.commit(updateCurrent, hydrate(response.data));
        },

        async create(context, newItem) {
            const response = await wrapRequest(context, async () => {
                return await resource.create(dehydrate(newItem));
            });

            context.commit(updateCurrent, hydrate(response.data));
        },

        async delete(context, itemToDelete) {
            const response = await wrapRequest(context, async () => {
                return await resource.delete(itemToDelete.id);
            });

            if (response.status < 400) {
                context.commit(deleteFromList, itemToDelete.id);
            }
        },
    };
}
