import { setPreviousRevision, setPreviousPublishedRevision } from './revisionedTypes';

export const revisionedMutations = {
    [setPreviousRevision](state, product) {
        state.previousRevision = product;
    },

    [setPreviousPublishedRevision](state, product) {
        state.previousPublishedRevision = product;
    },
};
