<template>
    <span
        :class="{selected, }"
        class="pa-2 mr-1 sectionTab"
        @click="onTabClick"
        @dblclick="onTabDblClick"
    >
        <span v-if="!showEditor" class="sectionName">{{ section.title }}</span>
        <tab-title-editor
            v-if="showEditor"
            :value="section.title"
            class="sectionName"
            @stopEdit="onStopTitleEdit"
            @input="onInput"
        />

        <v-icon v-if="deletable" v-ripple class="pointer deleteIcon" @click.stop="onDeleteClick">delete</v-icon>
    </span>
</template>

<script>
import TabTitleEditor from './TabTitleEditor';

export default {
    name: 'SectionTab',

    components: {
        TabTitleEditor,
    },

    props: {
        section: {
            type: Object,
            required: true,
        },

        selected: {
            type: Boolean,
            required: true,
        },

        editable: {
            type: Boolean,
            required: true,
        },

        deletable: {
            type: Boolean,
            required: true,
        },
        forceEditor: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['rename', 'delete', 'select'],

    data() {
        return {
            isEditing: false,
        };
    },

    computed: {
        showEditor() {
            if (this.editable) {
                return this.isEditing;
            } else {
                return false;
            }
        },
    },

    methods: {
        onTabClick() {
            this.$emit('select', this.section);
        },

        onTabDblClick() {
            if (this.editable) {
                this.isEditing = true;
            }
        },

        onDeleteClick() {
            if (this.editable) {
                this.$emit('delete', this.section);
            }
        },

        onStopTitleEdit() {
            this.isEditing = false;
        },

        onInput(title) {
            this.$emit('rename', {title, id: this.section.id});
        },
    },
};
</script>

<style scoped lang="stylus">
.sectionTab {
    cursor: pointer;
    font-size: 115%;
    border-style: solid;
    border-width: $tabBorderWidth;
    border-color: #2e73b6;
    border-bottom-color: transparent;
    border-radius: 2px 2px 0 0;

    &.selected {
        background-color: #afcde7;
        border-bottom-color: #afcde7;
    }

    .deleteIcon {
        opacity: 0;
        pointer-events: none;
        transition-duration: 500ms;
    }

    &:hover .deleteIcon {
        opacity: 1;
        pointer-events: auto;
        transition: all 150ms linear;
    }
}

.sectionName {
    min-width: 175px;
    display: inline-block;
}
</style>
