<template>
    <v-row class="columnItem fill-height" no-gutters justify="center" align="start" tag="article">
        <component
            :is="editorComponent"
            :content="column.content"
            :read-only="readOnly"
            :uuid="uuid"
            :column-index="columnIndex"
            @input="$emit('input', $event)"
        />
    </v-row>
</template>

<script>
import ColumnEditorText from './text/ColumnEditorText';
import ColumnEditorImage from './image/ColumnEditorImage';
import ColumnEditorGrid from './grid/ColumnEditorGrid';

const editorTypeMap = {
    text: ColumnEditorText,
    image: ColumnEditorImage,
    grid: ColumnEditorGrid,
};

export default {
    name: 'ColumnItem',

    components: {
        ColumnEditorGrid,
        ColumnEditorImage,
        ColumnEditorText,
    },

    props: {
        column: {
            type: Object,
            required: true,
        },
        columnIndex: {
            type: Number,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        uuid: {
            type: String,
            required: true,
        },
    },
    emits: ['input'],

    computed: {
        editorComponent() {
            return editorTypeMap[this.column.type];
        },
    },
};
</script>

<style scoped>
.columnItem {
    background-color: white;
    border: 1px solid silver;
}
</style>
